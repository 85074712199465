import { FC, useCallback } from 'react';
import { Layer, Source } from 'react-map-gl';
import { AnyLayout, AnyPaint, MapLayerMouseEvent } from 'mapbox-gl';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectParkingLotsGeo,
  selectParkingLotsCenterGeo,
  selectOffstreetZonesLayer,
  selectedParkingLotsActions,
} from '../../../../features';
import { MapImage } from '../../controls';
import { OffstreetZoneLayerType } from '../../../../model';
import { useMapLayerHover, useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { ParkingLotPopup } from './ParkingLotPopup';
import { NavigationSource, PopupType, amplitudeService } from '../../../../services';

const iconsPaint = {
  'text-color': 'black',
};

const polygonsFill: AnyPaint = {
  'fill-color': '#D13438',
  'fill-opacity': 0.3,
};

export const ParkingLotsLayer: FC = () => {
  const dispatch = useAppDispatch();
  const geoJson = useAppSelector(selectParkingLotsGeo);
  const geoCenterJson = useAppSelector(selectParkingLotsCenterGeo);
  const offstreetZoneLayer = useAppSelector(selectOffstreetZonesLayer);

  const layerEnabled = offstreetZoneLayer.enabled && offstreetZoneLayer.layerTypeFilter.isTagEnabled(OffstreetZoneLayerType.Lots);
  const iconsLayer = 'parking-lots-icon-layer';
  const layout: AnyLayout = {
    visibility: layerEnabled ? 'visible' : 'none',
  };
  const iconLayout: AnyLayout = {
    visibility: layerEnabled ? 'visible' : 'none',
    'icon-image': 'point-parking-lot',
    'icon-allow-overlap': true,
    'icon-size': {
      base: 0.4,
      stops: [
        [16, 0.6],
        [18, 0.8],
        [20, 1.4],
        [21, 1.6],
      ],
    },
  };

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      dispatch(selectedParkingLotsActions.closePopup());
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) return;

      const id = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(
          selectedParkingLotsActions.load({
            id: id,
            position: position,
          }),
        );
        amplitudeService.trackPopupOpen(PopupType.ParkingLot, NavigationSource.Map);
      }, 10);
    },
    [dispatch],
  );

  useMapToolTip(
    (event: MapLayerMouseEvent) => (event.features && event.features.length > 0 ? event.features[0].properties?.name : null),
    () => layerEnabled,
    iconsLayer,
  );

  useMapLayerHover([iconsLayer]);

  useMapLayerPopup(handleLayerClick, iconsLayer);

  return (
    <>
      <Source id='parking-lots-source' type='geojson' data={geoJson} generateId={true}>
        <Layer
          {...{
            id: 'parking-lots-layer',
            type: 'fill',
            layout: layout,
            paint: polygonsFill,
            before: 'building-extrusion',
          }}
        />
      </Source>
      <Source id='parking-lots-center-source' type='geojson' data={geoCenterJson} generateId={true}>
        <Layer
          {...{
            id: iconsLayer,
            type: 'symbol',
            layout: iconLayout,
            paint: iconsPaint,
            before: 'building-extrusion',
          }}
        />
      </Source>
      <ParkingLotPopup />
      <MapImage name='point-parking-lot' url='/icons/parkingLot.png' />
    </>
  );
};
