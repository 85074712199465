import { Action } from '@reduxjs/toolkit';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, catchError, concatMap, filter, from, map, mergeMap, of } from 'rxjs';

import { blockfaces, reports } from '../../../../services';
import { RootState } from '../../../../store';
import { mapStateActions } from '../../map-state';
import { selectedBlockfaceHeatmapsActions } from './selected-blockface-heatmaps-slice';
import { citiesActions } from '../../../common';

const loadHeatmap = (id: number, state: RootState) => {
  const existing = state.selectedBlockfaceHeatmaps.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return from(blockfaces.get(id)).pipe(
    map((x) => ({
      blockface: x,
    })),
  );
};

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((_) => selectedBlockfaceHeatmapsActions.closePopup()),
  );

const heatmapSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedBlockfaceHeatmapsActions.loadHeatmap.match),
    concatMap((action) =>
      loadHeatmap(action.payload.id, state$.value).pipe(
        mergeMap((x) =>
          of(
            selectedBlockfaceHeatmapsActions.loadHeatmapSuccess({
              heatmap: x,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              position: action.payload.position!,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              initPosition: action.payload.initPosition ? action.payload.initPosition : action.payload.position!,
            }),
          ),
        ),
        catchError((err) => of(selectedBlockfaceHeatmapsActions.loadHeatmapFailed(err.message))),
      ),
    ),
  );

const fetchDurationReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfaceHeatmapsActions.fetchDurationReport.match),
    concatMap((action) => {
      return from(reports.getBlockfaceParkingDurationReport(action.payload.id, action.payload.filter)).pipe(
        map((x) => selectedBlockfaceHeatmapsActions.fetchDurationReportSuccess({ id: action.payload.id, report: x })),
        catchError((err) => of(selectedBlockfaceHeatmapsActions.fetchReportFailed(err.message))),
      );
    }),
  );
};

const fetchViolationReportEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfaceHeatmapsActions.fetchViolationReport.match),
    concatMap((action) => {
      return from(reports.getBlockfaceEnforcementReport(action.payload.blockfaceId, action.payload.filter)).pipe(
        map((x) => selectedBlockfaceHeatmapsActions.fetchViolationReportSuccess({ blockfaceId: action.payload.blockfaceId, report: x })),
        catchError((err) => of(selectedBlockfaceHeatmapsActions.fetchViolationReportFailed(err.message))),
      );
    }),
  );
};

const fetchRevenueReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfaceHeatmapsActions.fetchRevenueReport.match),
    concatMap((action) => {
      return from(reports.getBlockfaceRevenueReport(action.payload.id, action.payload.filter)).pipe(
        map((x) => selectedBlockfaceHeatmapsActions.fetchRevenueReportSuccess({ id: action.payload.id, report: x })),
        catchError((err) => of(selectedBlockfaceHeatmapsActions.fetchReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyTrafficReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfaceHeatmapsActions.fetchOccupancyTrafficReport.match),
    concatMap((action) =>
      from(reports.getBlockfaceOccupancyTrafficReport(action.payload.id, action.payload.filter)).pipe(
        map((x) => selectedBlockfaceHeatmapsActions.fetchOccupancyTrafficReportSuccess({ id: action.payload.id, report: x })),
        catchError((err) => of(selectedBlockfaceHeatmapsActions.fetchReportFailed(err.message))),
      ),
    ),
  );
};

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap(() => of(selectedBlockfaceHeatmapsActions.collapsePopups())),
  );

export const blockfacesHeatmapEpic = combineEpics(
  heatmapSelectedEpic,
  fetchDurationReportEpic,
  fetchViolationReportEpic,
  fetchRevenueReportEpic,
  closePopupsEpic,
  fetchOccupancyTrafficReportEpic,
  citySelectedEpic,
);
