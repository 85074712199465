/* eslint-disable jsx-a11y/label-has-associated-control */
import { TabStripTab } from '@progress/kendo-react-layout';
import { Position } from 'geojson';
import React, { FC, useCallback, useMemo, useState } from 'react';

import {
  currentBlockface,
  selectSidebarState,
  selectedBlockfaces,
  selectedBlockfacesActions,
  selectedCurbSpacesActions,
  selectedMetersActions,
  selectedStudyAreasActions,
  sidebarActions,
} from '../../../../features';
import { useOccupancyTrafficEnabled, useOpenPopupDispatch, useReportBuilder } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { ISelectedBlockface, SidebarName } from '../../../../model';
import { ISelectedEntity, NavigationSource, PopupTab, PopupType, ReportName, amplitudeService } from '../../../../services';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  ContentPlaceholder,
  Link,
  PinButton,
  PopupBodyPlaceholder,
  PopupHeaderPlaceholder,
  SelfOrientedPopup,
  Spinner,
  TabStrip,
} from '../../../common';
import { PerformanceParkingLabel, RevenuePeriodSelector, ViewReportLink, ViolationsPeriodSelector } from '../../common';
import { OccupancyTrafficSelector } from '../../common/occupancy-traffic-popup-tab/OccupancyTrafficSelector';
import { BlockfaceIcon } from '../../icons';
import { CurbSpacesStatusCount } from '../curb-spaces/curb-spaces-status-count/CurbSpacesStatusCount';

import styles from './BlockfacePopup.module.scss';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';

const GENERAL_INFO_TAB = 0;
const REVENUE_TAB = 1;
const ENFORCEMENT_TAB = 2;
const OCCUPANCY_TRAFFIC_TAB = 3;

const TabToAmpTab = new Map<number, PopupTab>();
TabToAmpTab.set(GENERAL_INFO_TAB, PopupTab.BlockfacesGeneralInfo);
TabToAmpTab.set(REVENUE_TAB, PopupTab.BlockfacesRevenue);
TabToAmpTab.set(ENFORCEMENT_TAB, PopupTab.BlockfacesEnforcement);
TabToAmpTab.set(OCCUPANCY_TRAFFIC_TAB, PopupTab.BlockfacesOccupancyTraffic);

const ItemsCount: FC<{ count: number }> = ({ count }) =>
  count > 0 ? (
    <div className={styles.labelDescription}>
      {count} {count === 1 ? 'item' : 'items'}
    </div>
  ) : null;

const PopupItem: FC<{ blockfaceData: ISelectedEntity<ISelectedBlockface> }> = ({ blockfaceData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const localization = useExtendedLocalization();
  const [selectedTab, setSelectedTab] = useState(0);
  const selectedSidebar = useAppSelector(selectSidebarState);
  const currentBlockfaceData = useAppSelector(currentBlockface);
  const reportsBuilder = useReportBuilder();
  const occupancyTrafficEnabled = useOccupancyTrafficEnabled();

  const blockface = blockfaceData.entity;

  const onDragEnd = useCallback(
    (position: Position) => {
      if (!blockface) {
        return;
      }
      dispatch(selectedBlockfacesActions.change({ id: blockface.Id, position: position }));
      amplitudeService.trackPopupMove(PopupType.Blockfaces);
    },
    [blockface, dispatch],
  );

  const closePopup = useCallback(() => {
    dispatch(selectedBlockfacesActions.closePopup(blockfaceData.id));
  }, [blockfaceData.id, dispatch]);

  const pinPopup = useCallback(() => {
    dispatch(selectedBlockfacesActions.pinPopup(blockfaceData.id));
  }, [blockfaceData.id, dispatch]);

  const moreInfo = useCallback(() => {
    dispatch(selectedBlockfacesActions.setCurrent(blockface));
    dispatch(sidebarActions.setSelected({ name: SidebarName.blockface, data: blockface?.Id }));
  }, [blockface, dispatch]);

  const onTabSelect = useCallback(
    (tab: number) => {
      if (selectedTab === tab) return;
      setSelectedTab(tab);

      const ampTab = TabToAmpTab.get(tab);
      if (ampTab !== undefined) {
        amplitudeService.trackPopupTab(ampTab);
      }
    },
    [selectedTab],
  );

  const openMeter = useCallback(
    (meterId: number) => {
      popupDispatch(selectedMetersActions.loadMeter({ id: meterId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openSpot = useCallback(
    (spotId: number) => {
      popupDispatch(selectedCurbSpacesActions.load({ id: spotId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openStudyArea = useCallback(
    (studyAreaId: number) => {
      popupDispatch(selectedStudyAreasActions.loadStudyArea({ id: studyAreaId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const getViolationReport = useCallback(
    (filter: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => {
      dispatch(
        selectedBlockfacesActions.fetchViolationReport({
          blockfaceId: blockfaceData.id,
          filter: filter.heatmapPeriod.calcReportFilter(filter.period),
        }),
      );
    },
    [dispatch, blockfaceData.id],
  );

  const report = useMemo(() => {
    return blockface
      ? selectedTab === REVENUE_TAB
        ? { url: reportsBuilder.getBlockfaceRevenue(blockface.Id), name: ReportName.BlockfaceRevenue }
        : selectedTab === ENFORCEMENT_TAB
        ? { url: reportsBuilder.getOverviewEnforcement(), name: ReportName.Enforcement }
        : selectedTab === OCCUPANCY_TRAFFIC_TAB
        ? { url: reportsBuilder.getOverviewOccupancy(), name: ReportName.OccupancyOverview }
        : null
      : null;
  }, [reportsBuilder, selectedTab, blockface]);

  const popupContent = useMemo(
    () => (
      <>
        <ContentPlaceholder loading={blockfaceData.loading} element={<PopupHeaderPlaceholder />}>
          <div className={`map-popup-header ${styles.header}`}>
            <BlockfaceIcon />
            <h4>Blockface: #{blockface?.Code} </h4>
            <CurbSpacesStatusCount
              spotsStates={blockface?.spotsStates.map((x) => x.Status) || []}
              spotsCount={blockface?.SpotsCount}
              className={styles.curbSpacesStatusCount}
            />
            <PinButton className={styles.pinButton} pinned={blockfaceData.pinnedPopup} onClick={pinPopup} />
          </div>
        </ContentPlaceholder>

        <div className='map-popup-body'>
          <TabStrip onSelectTab={onTabSelect}>
            <TabStripTab title={localization.toLanguageStringF('common.generalInfo')}>
              <ContentPlaceholder loading={blockfaceData.loading} element={<PopupBodyPlaceholder />}>
                <>
                  {blockface?.PerformanceParking && <PerformanceParkingLabel />}

                  <div className={styles.tabContent}>
                    {blockface && blockface.studyAreas.length > 0 && (
                      <div className='map-popup-row'>
                        <div className={styles.columnLabel}>
                          <label>{localization.toLanguageStringF('blockface.studyAreas')}:</label>
                        </div>
                        <div className='row-value'>
                          {blockface.studyAreas.map((x, idx) => (
                            <Link key={x.Id} onClick={() => openStudyArea(x.Id)} className={styles.listItem}>
                              <span>{x.Name}</span>
                              {idx < blockface.studyAreas.length - 1 && ','}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className='map-popup-row'>
                      <div className={styles.columnLabel}>
                        <label>{localization.toLanguageStringF('blockface.popup.address')}:</label>
                      </div>
                      <div className='row-value'>{blockface?.Address}</div>
                    </div>

                    <div className='map-popup-row'>
                      <div className={styles.columnLabel}>
                        <label>{localization.toLanguageStringF('common.parkingSpacesCount')}:</label>
                      </div>
                      <div className='row-value'>{blockface?.SpotsCount}</div>
                    </div>

                    {blockface && blockface.metersNames.length > 0 && (
                      <div className='map-popup-row'>
                        <div className={styles.columnLabel}>
                          <label>{localization.toLanguageStringF('blockface.popup.meters')}:</label>
                          <ItemsCount count={blockface.metersNames.length} />
                        </div>
                        <div className='row-value' style={{ width: '100%' }}>
                          {blockface.metersNames.map((x, idx) => (
                            <Link key={x.Id} onClick={() => openMeter(x.Id)} className={styles.listItem}>
                              <span>{x.Name}</span>
                              {idx < blockface.metersNames.length - 1 && ','}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}

                    {blockface && blockface.sensors.length > 0 && (
                      <div className='map-popup-row'>
                        <div className={styles.columnLabel}>
                          <label>{localization.toLanguageStringF('blockface.popup.sensors')}:</label>
                          <ItemsCount count={blockface.sensors.length} />
                        </div>
                        <div className='row-value' style={{ width: '100%' }}>
                          {blockface.sensors.map((x, idx) => (
                            <Link key={x.SensorId} onClick={() => openSpot(x.SpotId)} className={styles.listItem}>
                              <span>{x.SensorName}</span>
                              {idx < blockface.sensors.length - 1 && ','}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </ContentPlaceholder>
            </TabStripTab>
            <TabStripTab title={localization.toLanguageStringF('blockface.revenue')}>
              <ContentPlaceholder loading={blockfaceData.loading} element={<PopupBodyPlaceholder />}>
                <RevenuePeriodSelector blockfaceId={blockfaceData.id} report={blockface?.revenueReport} />
              </ContentPlaceholder>
            </TabStripTab>
            <TabStripTab title={localization.toLanguageStringF('zone.enforcement')}>
              <ContentPlaceholder loading={blockfaceData.loading} element={<PopupBodyPlaceholder />}>
                <ViolationsPeriodSelector onChangeFilter={getViolationReport} report={blockface?.violationReport} />
              </ContentPlaceholder>
            </TabStripTab>
            {occupancyTrafficEnabled && (
              <TabStripTab title={localization.toLanguageStringF('blockface.occupancyTraffic')}>
                <ContentPlaceholder loading={blockfaceData.loading} element={<PopupBodyPlaceholder />}>
                  <OccupancyTrafficSelector
                    report={blockfaceData.entity?.occupancyTrafficReportData}
                    onChangeFilter={(change) =>
                      dispatch(
                        selectedBlockfacesActions.fetchOccupancyTrafficRepor({
                          blockfaceId: blockfaceData.id,
                          filter: change.heatmapPeriod.calcReportFilter(change.period),
                        }),
                      )
                    }
                  />
                </ContentPlaceholder>
              </TabStripTab>
            )}
          </TabStrip>

          <div className={`map-popup-row more-info ${styles.moreInfo}`}>
            {report && <ViewReportLink reportUrl={report.url} reportName={report.name} className={styles.reportLink} />}
            <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.blockface && currentBlockfaceData?.id === blockface?.Id}>
              {localization.toLanguageStringF('common.moreInfo')}
            </Link>
          </div>
        </div>
      </>
    ),
    [
      blockface,
      blockfaceData.entity?.occupancyTrafficReportData,
      blockfaceData.id,
      blockfaceData.loading,
      blockfaceData.pinnedPopup,
      currentBlockfaceData?.id,
      dispatch,
      getViolationReport,
      localization,
      moreInfo,
      onTabSelect,
      openMeter,
      openSpot,
      openStudyArea,
      pinPopup,
      selectedSidebar,
      report,
      occupancyTrafficEnabled,
    ],
  );

  const content = useMemo(() => {
    if (!blockfaceData.openPopup || !blockfaceData.position) return null;

    return (
      <React.StrictMode>
        <SelfOrientedPopup
          data={blockface}
          maxWidth='690px'
          longitude={blockfaceData.position[0]}
          latitude={blockfaceData.position[1]}
          onClose={closePopup}
          closeOnClick={false}
          className='blockface-popup'
          updateOn={selectedTab}
          initPosition={blockfaceData.initPosition}
          onDragEnd={onDragEnd}
        >
          <div>
            {popupContent}
            {blockfaceData.loading && <Spinner backdrop={true} />}
          </div>
        </SelfOrientedPopup>
      </React.StrictMode>
    );
  }, [
    blockface,
    blockfaceData.initPosition,
    blockfaceData.loading,
    blockfaceData.openPopup,
    blockfaceData.position,
    closePopup,
    onDragEnd,
    popupContent,
    selectedTab,
  ]);

  return content;
};

export const BlockfacePopup: FC = () => {
  const blockfacesData = useAppSelector(selectedBlockfaces);
  const blockfaces = blockfacesData.selected;

  const content = useMemo(() => {
    return (
      <>
        {blockfaces.map((x) => (
          <PopupItem key={x.id} blockfaceData={x} />
        ))}
      </>
    );
  }, [blockfaces]);

  return content;
};
