/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useCallback } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';

import {
  selectAreaTypeNames,
  selectedAreaActions,
  selectedZonesActions,
  sidebarActions,
  selectedMetersActions,
  currentZone,
  selectedCurbSpacesActions,
  selectedStudyAreasActions,
} from '../../../../features';
import { ISelectedZone, SidebarName, SpotOccupancyCalculator } from '../../../../model';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { MapSidebarContainer } from '../../controls';
import { Link } from '../../../common';
import { RevenueTable } from '../../../revenue';
import { CameraThumbnail } from '../cameras/camera-thumbnail/CameraThumbnail';
import { SignThumbnail } from '../signs/sign-thumbnail/SignThumbnail';
import { useOccupancyTrafficEnabled, useOpenPopupDispatch, useReportBuilder } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { EnforcementTable } from '../../common/enforcement-table/EnforcementTable';

import styles from './ZoneSidebar.module.scss';

import { ZoneKpiTable } from '../../common/zone-kpi-table/ZoneKpiTable';
import { ISelectedEntity, NavigationSource, PopupType, ReportName, amplitudeService } from '../../../../services';
import { TrafficTable } from '../../common/traffic-table/TrafficTable';
import { OccupancyTable, SidebarSectionLazyLoader } from '../../common';

const SidebarRow: FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <div className='map-sidebar-row'>
    <label>{title}</label>
    <div>{children}</div>
  </div>
);

interface Props {
  zoneData: ISelectedEntity<ISelectedZone>;
}

const Sidebar: FC<Props> = ({ zoneData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const areaTypes = useAppSelector(selectAreaTypeNames);
  const zone = zoneData.entity;
  const localization = useExtendedLocalization();
  const reportsBuilder = useReportBuilder();
  const occupancyTrafficEnabled = useOccupancyTrafficEnabled();

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const mapViewClick = () => {
    zone && popupDispatch(selectedZonesActions.openPopup(zone.Id));
  };

  const openArea = useCallback(
    (areaId: number | undefined) => {
      areaId && popupDispatch(selectedAreaActions.loadArea({ id: areaId, position: null }));
    },
    [popupDispatch],
  );

  const openMeter = useCallback(
    (meterId: number) => {
      popupDispatch(selectedMetersActions.loadMeter({ id: meterId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openSpot = (spotId: number) => {
    popupDispatch(selectedCurbSpacesActions.load({ id: spotId, position: null }));
    amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Navigation);
  };

  const openStudyArea = (studyAreaId: number) => {
    popupDispatch(selectedStudyAreasActions.loadStudyArea({ id: studyAreaId, position: null }));
    amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Navigation);
  };

  if (!zone) {
    return null;
  }

  const revenueReportUrl = reportsBuilder.getZoneRevenue(zone.Id);

  const revenueMoreInfo = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (revenueReportUrl) {
      window.open(revenueReportUrl, '_blank');
      amplitudeService.trackReport(ReportName.ZoneRevenue, NavigationSource.Sidebar);
    }
  };

  const areaType = zone?.area ? areaTypes[zone?.area?.TypeId] : null;
  const spotsCount = SpotOccupancyCalculator.getCountSpotStatuses(
    zone.spotsStates.map((x) => x.Status),
    zone.SpotsCount,
  );

  return (
    <div className='map-sidebar map-sidebar map-sidebar-info'>
      <div className='map-sidebar-header'>
        <h3>Zone: #{zone?.Code}</h3>
        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>

      <div className='map-sidebar-body'>
        <PanelBar keepItemsMounted={true}>
          <PanelBarItem title={localization.toLanguageStringF('common.generalInfo')} expanded={true}>
            <div>
              {zone.studyAreas.length > 0 && (
                <SidebarRow title={`${localization.toLanguageStringF('zone.studyAreas')}:`}>
                  {zone.studyAreas.map((x, idx) => (
                    <Link key={x.Id} onClick={() => openStudyArea(x.Id)} className={styles.listItem}>
                      <span>{x.Name}</span>
                      {idx < zone.studyAreas.length - 1 && ','}
                    </Link>
                  ))}
                </SidebarRow>
              )}
              <SidebarRow title={`${localization.toLanguageStringF('zone.sidebar.address')}:`}>{zone.Address}</SidebarRow>
              <SidebarRow title='Vacant:'>{spotsCount.vacant}</SidebarRow>
              <SidebarRow title='Booked:'>{spotsCount.booked}</SidebarRow>
              <SidebarRow title='Occupied:'>{spotsCount.occupied}</SidebarRow>
              <SidebarRow title='Not Monitored:'>{spotsCount.notMonitored}</SidebarRow>
              {areaType && (
                <SidebarRow title={areaType.name + ':'}>
                  <Link onClick={() => openArea(zone?.area?.Id)}>{zone?.area?.Name}</Link>
                </SidebarRow>
              )}
              <SidebarRow title={`${localization.toLanguageStringF('common.parkingSpacesCount')}:`}>{zone.SpotsCount}</SidebarRow>
            </div>
            <div className='map-sidebar-row more-info'>
              <Link onClick={mapViewClick} className='more-info-button' disabled={zoneData.openPopup}>
                {localization.toLanguageStringF('common.mapView')}
              </Link>
            </div>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('zone.revenue')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(zone.revenue?.length || 0) > 0}
              shouldLoadData={zone.revenue === undefined}
              loadData={() => dispatch(selectedZonesActions.fetchRevenue(zone.Id))}
            >
              <RevenueTable revenue={zone.revenue || []} />
              <div className='map-sidebar-row more-info'>
                {revenueReportUrl && (
                  <Link onClick={revenueMoreInfo} className='more-info-button'>
                    {localization.toLanguageStringF('common.moreInfo')}
                  </Link>
                )}
              </div>
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('common.report.kpi')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(zone.kpiReport?.length || 0) > 0}
              shouldLoadData={zone.kpiReport === undefined}
              loadData={() => dispatch(selectedZonesActions.fetchKpiReport(zone.Id))}
            >
              <ZoneKpiTable kpiReport={zone.kpiReport || []} className={styles.panelMargin} />
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('zone.enforcement')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(zone.violationMetricReport?.length || 0) > 0}
              shouldLoadData={zone.violationMetricReport === undefined}
              loadData={() => dispatch(selectedZonesActions.fetchViolationMetricReport(zone.Id))}
            >
              <EnforcementTable metrics={zone.violationMetricReport || []} className={styles.panelMargin} />
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('common.report.traffic')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(zone.trafficReport?.length || 0) > 0}
              shouldLoadData={zone.trafficReport === undefined}
              loadData={() => dispatch(selectedZonesActions.fetchTrafficReport(zone.Id))}
            >
              <TrafficTable report={zone.trafficReport || []} className={styles.panelMargin} />
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          {occupancyTrafficEnabled && (
            <PanelBarItem title={localization.toLanguageStringF('common.report.occupancy')} expanded={false}>
              <SidebarSectionLazyLoader
                hasData={(zone.occupancyReport?.length || 0) > 0}
                shouldLoadData={zone.occupancyReport === undefined}
                loadData={() => dispatch(selectedZonesActions.fetchOccupancyReport(zone.Id))}
              >
                <OccupancyTable occupancyReport={zone.occupancyReport || []} />
              </SidebarSectionLazyLoader>
            </PanelBarItem>
          )}

          {zone.camerasStates.length > 0 && (
            <PanelBarItem title={localization.toLanguageStringF('zone.sidebar.cameras')} expanded={false}>
              <div className={styles.wrapContainer}>
                {zone.camerasStates.map((x) => {
                  return (
                    <div key={x.CameraId} className={styles.thumbnail}>
                      <CameraThumbnail state={x} />
                    </div>
                  );
                })}
              </div>
            </PanelBarItem>
          )}

          {zone.signsStates.length > 0 && (
            <PanelBarItem title={localization.toLanguageStringF('zone.sidebar.signs')} expanded={false}>
              <div className={styles.wrapContainer}>
                {zone.signsStates.map((x) => (
                  <div key={x.SignId} className={styles.thumbnail}>
                    <SignThumbnail state={x} />
                  </div>
                ))}
              </div>
            </PanelBarItem>
          )}

          {zone.metersNames.length > 0 && (
            <PanelBarItem title={localization.toLanguageStringF('zone.sidebar.meters')} expanded={false} className='panelbar-scrollable'>
              <div className={styles.wrapContainer}>
                {zone.metersNames.map((x, idx) => (
                  <Link key={x.Id} onClick={() => openMeter(x.Id)} className={styles.linkButton}>
                    <span>{x.Name}</span>
                    {idx < zone.metersNames.length - 1 && ','}
                  </Link>
                ))}
              </div>
            </PanelBarItem>
          )}

          {zone.sensors.length > 0 && (
            <PanelBarItem title={localization.toLanguageStringF('zone.sidebar.sensors')} expanded={false} className='panelbar-scrollable'>
              <div className={styles.wrapContainer}>
                {zone.sensors.map((x, idx) => (
                  <Link key={x.SensorId} onClick={() => openSpot(x.SpotId)} className={styles.linkButton}>
                    <span>{x.SensorName}</span>
                    {idx < zone.sensors.length - 1 && ','}
                  </Link>
                ))}
              </div>
            </PanelBarItem>
          )}
        </PanelBar>
      </div>
    </div>
  );
};

export const ZoneSidebar: FC = () => {
  const current = useAppSelector(currentZone);

  return current ? (
    <MapSidebarContainer name={SidebarName.zone}>
      <Sidebar zoneData={current} />
    </MapSidebarContainer>
  ) : null;
};
