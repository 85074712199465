import { FC, useMemo } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { IOccupancyReportItem, Period } from '../../../../model';
import { MetricsTable } from '../metrics-table-table/MetricsTable';
import { dateUtils } from '../../../../utils';

const periods = [Period.Yesterday, Period.Week, Period.Month, Period.Quarter];

export const OccupancyTable: FC<{ occupancyReport: Array<IOccupancyReportItem> }> = ({ occupancyReport }) => {
  const localization = useExtendedLocalization();
  const metricItems = useMemo(
    () => [
      {
        name: localization.toLanguageStringF('occupancy.occupancyReport.avgOccupancy'),
        values: Object.fromEntries(
          periods.map((x) => [
            x,
            {
              value: `${occupancyReport.find((y) => y.Period === x)?.OccupancyRate ?? 0}%`,
              change: occupancyReport.find((y) => y.Period === x)?.DiffOccupancyRate ?? 0,
              isMoney: false,
            },
          ]),
        ),
      },
      {
        name: localization.toLanguageStringF('occupancy.occupancyReport.avgTurnover'),
        values: Object.fromEntries(
          periods.map((x) => [
            x,
            {
              value: occupancyReport.find((y) => y.Period === x)?.Turnover ?? 0,
              change: occupancyReport.find((y) => y.Period === x)?.DiffTurnover ?? 0,
              isMoney: false,
            },
          ]),
        ),
      },
      {
        name: localization.toLanguageStringF('occupancy.occupancyReport.avgDwellTime'),
        values: Object.fromEntries(
          periods.map((x) => [
            x,
            {
              value: dateUtils.formatMinutes(occupancyReport.find((y) => y.Period === x)?.DwellTime ?? 0),
              change: occupancyReport.find((y) => y.Period === x)?.DiffDwellTime ?? 0,
              isMoney: false,
            },
          ]),
        ),
      },
    ],
    [localization, occupancyReport],
  );

  return <MetricsTable metricItems={metricItems} periods={periods}></MetricsTable>;
};
