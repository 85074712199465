/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useCallback, useState } from 'react';
import { PopupEvent } from 'react-map-gl';
import { currentMeter, ISelectedMeter, selectedMeters, selectedZonesActions, selectSidebarState } from '../../../../features';
import {
  metersEditingActions,
  selectedBlockfacesActions,
  selectedMetersActions,
  selectedStudyAreasActions,
  selectMetersEditing,
  selectMetersLayer,
  sidebarActions,
} from '../../../../features/map';
import { useOpenPopupDispatch } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { MeterType, MeterVendor, SidebarName } from '../../../../model';
import { ISelectedEntity, NavigationSource, PopupType, amplitudeService, notifications } from '../../../../services';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { GeoCoordinatesEditor, GeoCoordinatesText, Link, PinButton, PopupPlaceholder, SelfOrientedPopup } from '../../../common';
import { MeterVendorIcon } from '../../icons';
import { MeterTypeIcon } from '../../icons/meter-type-icon/MeterTypeIcon';
import { CurbSpacesList } from '../curb-spaces/curb-spaces-list/CurbSpacesList';
import { MeterStatusText } from './MeterStatusText';
import { MeterTypeText } from './MeterTypeText';
import { Position } from 'geojson';
import { PerformanceParkingLabel } from '../../common';

import styles from './MeterPopup.module.scss';

export const PopupItem: FC<{ selectedMeterData: ISelectedEntity<ISelectedMeter> }> = ({ selectedMeterData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const currentMeterData = useAppSelector(currentMeter);
  const selectMetersLayerData = useAppSelector(selectMetersLayer);
  const selectedSidebar = useAppSelector(selectSidebarState);
  const metersEditing = useAppSelector(selectMetersEditing);
  const [editMode, setEditMode] = useState(false);
  const localization = useExtendedLocalization();

  const meter = selectedMeterData.entity;

  const openZone = useCallback(
    (zoneId: number | null) => {
      if (zoneId) {
        popupDispatch(selectedZonesActions.loadZone({ id: zoneId, position: null }));
        amplitudeService.trackPopupOpen(PopupType.Zones, NavigationSource.Navigation);
      }
    },
    [popupDispatch],
  );

  const openBlockface = (blockfaceId: number | null) => {
    if (blockfaceId) {
      popupDispatch(selectedBlockfacesActions.loadBlockface({ id: blockfaceId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Blockfaces, NavigationSource.Navigation);
    }
  };

  const openStudyArea = useCallback(
    (studyAreaId: number) => {
      popupDispatch(selectedStudyAreasActions.loadStudyArea({ id: studyAreaId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const closePopup = (event: PopupEvent) => {
    dispatch(selectedMetersActions.closePopup(selectedMeterData.id));
  };

  const pinPopup = () => {
    dispatch(selectedMetersActions.pinPopup(selectedMeterData.id));
  };

  const moreInfo = () => {
    dispatch(selectedMetersActions.setCurrent(meter));
    dispatch(sidebarActions.setSelected({ name: SidebarName.meter, data: meter?.Id }));
  };

  const applyNewPosition = useCallback(
    (position: [number, number]) => {
      if (selectedMeterData.feature) {
        dispatch(metersEditingActions.moveMeter({ meterFeature: selectedMeterData.feature, position }));
        setEditMode(false);
        notifications.success(localization.toLanguageStringF('meter.popup.changesSavedMessage'));
      }
    },
    [dispatch, localization, selectedMeterData.feature],
  );

  const onDragEnd = (position: Position) => {
    if (!meter) {
      return;
    }
    dispatch(selectedMetersActions.change({ id: meter.Id, position: position }));
    amplitudeService.trackPopupMove(PopupType.Meters);
  };

  if (!selectedMeterData.openPopup || !selectedMeterData.position) return null;

  const content = (
    <>
      <div className={`map-popup-header ${styles.header}`}>
        {meter && <MeterTypeIcon typeId={meter.TypeId} className={`${styles.typeIcon}`} />}
        <h4>{localization.toLanguageStringF('meter.popup.title', [meter?.Name ?? meter?.Id])}</h4>
        {meter && (
          <>
            <MeterTypeText className={`${styles.headerDescription}`} typeId={meter.TypeId} />
            &nbsp;|&nbsp;
            <MeterStatusText status={meter.Status} />
          </>
        )}
        <PinButton className={styles.pinButton} pinned={selectedMeterData.pinnedPopup} onClick={pinPopup} />
      </div>

      {metersEditing.isEditing && meter?.TypeId === MeterType.SingleSpot ? (
        <div className={`${styles.editCoordinates} map-popup-body`}>
          <h5>{localization.toLanguageStringF('meter.popup.editMode')}</h5>
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('meter.popup.currentGeo')}:</label>
            <div className='row-value'>
              <GeoCoordinatesText position={meter.Position} />
              <button className={styles.btnEdit} onClick={() => setEditMode(true)}>
                <i className='k-icon k-i-pencil'></i>
              </button>
            </div>
          </div>
          {editMode ? (
            <div className='map-popup-row'>
              <label>{localization.toLanguageStringF('meter.popup.targetGeo')}:</label>
              <div className='row-value'>
                <GeoCoordinatesEditor position={meter.Position} onApply={applyNewPosition} onCancel={() => setEditMode(false)} />
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className='map-popup-body'>
        {meter?.PerformanceParking && <PerformanceParkingLabel />}

        <div className={styles.columns}>
          <div>
            {meter?.Model && (
              <div className='map-popup-row picture-row'>
                <label htmlFor='fff'>{localization.toLanguageStringF('common.model')}:</label>
                <div className='row-value'>{meter.Model}</div>
              </div>
            )}

            {meter && (
              <div className='map-popup-row'>
                <label htmlFor='fff'>{localization.toLanguageStringF('common.vendor')}:</label>
                <div className='row-value'>{MeterVendor.getName(selectMetersLayerData.vendors.find((x) => x.Id === meter.VendorId))}</div>
              </div>
            )}

            {meter && (
              <div className='map-popup-row'>
                <label htmlFor='fff'>{localization.toLanguageStringF('common.address')}:</label>
                <div className='row-value'>{meter.Address}</div>
              </div>
            )}

            {meter && meter.studyAreas.length > 0 && (
              <div className='map-popup-row'>
                <label>{localization.toLanguageStringF('meter.studyAreas')}:</label>
                <div className='row-value'>
                  {meter.studyAreas.map((x, idx) => (
                    <Link key={x.Id} onClick={() => openStudyArea(x.Id)} className={styles.listItem}>
                      <span>{x.Name}</span>
                      {idx < meter.studyAreas.length - 1 && ','}
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {meter?.ZoneId && (
              <div className='map-popup-row'>
                <label htmlFor='fff'>{localization.toLanguageStringF('common.zone')}:</label>
                <div className='row-value'>
                  <Link className={styles.link} onClick={() => openZone(meter.ZoneId)}>
                    {meter.zone?.Name}
                  </Link>
                </div>
              </div>
            )}

            {meter?.BlockfaceId && (
              <div className='map-popup-row'>
                <label htmlFor='fff'>{localization.toLanguageStringF('common.blockface')}:</label>
                <div className='row-value'>
                  <Link className={styles.link} onClick={() => openBlockface(meter.BlockfaceId)}>
                    {meter.blockface?.Name}
                  </Link>
                </div>
              </div>
            )}

            {meter && (
              <div className='map-popup-row'>
                {meter.TypeId === MeterType.MultiSpot && meter.SpotsCount !== null && meter.SpotsCount !== undefined && (
                  <>
                    <label htmlFor='fff'>{localization.toLanguageStringF('common.parkingSpacesCount')}:</label>
                    <div className='row-value'>{meter.SpotsCount}</div>
                  </>
                )}
                {meter.TypeId !== MeterType.MultiSpot && meter.spotsStates.length > 0 && (
                  <>
                    <label htmlFor='fff'>{localization.toLanguageStringF('common.curbSpace')}:</label>
                    <div className='row-value'>
                      <CurbSpacesList spotsStates={meter.spotsStates} showIndex={false} />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          {meter && <MeterVendorIcon className={styles.vendorIcon} vendorId={meter.VendorId} />}
        </div>

        <div className='map-popup-row more-info'>
          <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.meter && currentMeterData?.id === meter?.Id}>
            {localization.toLanguageStringF('common.moreInfo')}
          </Link>
        </div>
      </div>
    </>
  );

  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={meter}
        longitude={selectedMeterData.position[0]}
        latitude={selectedMeterData.position[1]}
        onClose={closePopup}
        closeOnClick={false}
        className='meter-popup'
        maxWidth='340px'
        initPosition={selectedMeterData.initPosition}
        onDragEnd={onDragEnd}
      >
        <div className={`${styles.popup}`}>
          <PopupPlaceholder loading={selectedMeterData.loading}>{content}</PopupPlaceholder>
        </div>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};

export const MeterPopup: FC = () => {
  const data = useAppSelector(selectedMeters);
  const items = data.selected;

  return (
    <>
      {items.map((x) => (
        <PopupItem key={x.id} selectedMeterData={x} />
      ))}
    </>
  );
};
