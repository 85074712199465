import { getApi } from './api';
import { ITransaction } from '../../model/api/meter-transactions';

const BASE_URL = '/transaction';

const getTopTransactionsByMeter = (meterId: number): Promise<Array<ITransaction>> =>
  getApi()
    .get<Array<ITransaction>>(`${BASE_URL}/by-meter?id=${meterId}`)
    .then((response) => response.data || [])
    .then((transactions) =>
      transactions.map((x) => ({
        ...x,
        SessionStart: new Date(x.SessionStart),
        SessionEnd: x.SessionEnd ? new Date(x.SessionEnd) : null,
      })),
    );

export const transactions = {
  getTopMeterTransactions: getTopTransactionsByMeter,
};
