import { Action, AnyAction } from '@reduxjs/toolkit';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { combineEpics } from 'redux-observable';
import { Observable, from, of, zip } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { EMPTY_FEATURE_COLLECTION } from '../../../../constants';
import { ISelectedOffstreetZone, OffstreetZoneLayerType } from '../../../../model';
import { areas, geoProcessor, offstreetZones, parkingLots, reports, revenueData } from '../../../../services';
import { store } from '../../../../store';
import { geoUtils } from '../../../../utils';
import { citiesActions } from '../../../common';
import { mapStateActions } from '../../map-state';
import { offstreetZonesGeoActions } from './offstreet-zones-geo-slice';
import { offstreetZonesLayerActions } from './offstreet-zones-layer-slice';
import { selectedOffstreetZonesActions } from './selected-offstreet-zones-slice';

const fetchOffstreetZonesEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(offstreetZonesGeoActions.fetch.match),
    switchMap((action) => {
      if (action.payload.types.isTagEnabled(OffstreetZoneLayerType.Garages)) {
        return geoProcessor.loadOffstreetZones(action.payload.box, action.payload.box.zoom, action.payload.vendors).pipe(
          map((x) => offstreetZonesGeoActions.fetchSuccess(x)),
          catchError((err) => of(offstreetZonesGeoActions.fetchFailed(err.message))),
        );
      } else {
        return of(offstreetZonesGeoActions.fetchSuccess(EMPTY_FEATURE_COLLECTION));
      }
    }),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap(() =>
      of(
        offstreetZonesLayerActions.fetchVendors(),
        offstreetZonesLayerActions.fetchCount(),
        selectedOffstreetZonesActions.collapsePopups(),
      ),
    ),
  );

const fetchVendorsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(offstreetZonesLayerActions.fetchVendors.match),
    concatMap(() =>
      from(offstreetZones.getVendors()).pipe(
        map((x) => offstreetZonesLayerActions.fetchVendorsSuccess(x)),
        catchError((err) => of(offstreetZonesLayerActions.fetchVendorsFailed(err.message))),
      ),
    ),
  );

const offstreetZoneSelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(selectedOffstreetZonesActions.loadZone.match),
    concatMap((action) =>
      loadOffstreetZone(action.payload.id).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              offstreetZonesLayerActions.setEnabled(true),
              selectedOffstreetZonesActions.loadZoneSuccess({
                zone: x,
                position: center,
                initPosition: initPosition ? initPosition : center,
              }),
            );
          } else {
            const feature: Feature<Geometry, GeoJsonProperties> = findZoneFeature(action.payload.id) || {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: x.Position,
              },
              properties: {},
            };

            const center = geoUtils.findCenter(feature).geometry.coordinates;
            return of(
              offstreetZonesLayerActions.setEnabled(true),
              mapStateActions.setMapCenter(center),
              selectedOffstreetZonesActions.loadZoneSuccess({ zone: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedOffstreetZonesActions.loadZoneFailed(err.message))),
      ),
    ),
  );

const moveToZoneEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(selectedOffstreetZonesActions.moveToZone.match),
    map((action) => {
      const center = action.payload.position;
      if (center) return mapStateActions.setMapCenter(center);

      return selectedOffstreetZonesActions.moveToZoneFailed(`Center not found for Offstreet zone ${action.payload.id}.`);
    }),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((action) => selectedOffstreetZonesActions.closePopup()),
  );

const fetchCount = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(offstreetZonesLayerActions.fetchCount.match),
    concatMap(() =>
      zip(from(offstreetZones.getCount()), from(parkingLots.getCount())).pipe(
        mergeMap(([garagesCount, parkingLotsCount]) =>
          of(offstreetZonesLayerActions.fetchCountSuccess({ garagesCount, parkingLotsCount })),
        ),
        catchError((err) => of(offstreetZonesLayerActions.fetchCountFailed(err.message))),
      ),
    ),
  );

function loadOffstreetZone(zoneId: number): Observable<ISelectedOffstreetZone> {
  const state = store.getState();
  const existing = state.selectedOffstreetZones.selected.find((x) => x.id === zoneId);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return zip(from(offstreetZones.get(zoneId)), from(offstreetZones.getVendors())).pipe(
    mergeMap(([zone, vendors]) => {
      const normalizedVendors = vendors.map((v) => (v.Name === 'Unknown' ? { Id: v.Id, Name: 'Other', Count: v.Count } : v));
      const vendor = normalizedVendors.find((x) => x.Id === zone.VendorId);

      if (zone.AreaId) {
        return from(areas.get(zone.AreaId)).pipe(map((res) => ({ ...zone, area: res, vendor })));
      }

      return of({ ...zone, area: null, vendor });
    }),
  );
}

const fetchRevenueReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedOffstreetZonesActions.fetchRevenueReport.match),
    concatMap((action) => {
      return from(reports.getOffstreetZoneRevenueReport(action.payload.zoneId, action.payload.filter)).pipe(
        map((x) => selectedOffstreetZonesActions.fetchRevenueReportSuccess({ zoneId: action.payload.zoneId, report: x })),
        catchError((err) => of(selectedOffstreetZonesActions.fetchRevenueReportFailed(err.message))),
      );
    }),
  );
};

const fetchRevenueEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedOffstreetZonesActions.fetchRevenue.match),
    concatMap((action) => {
      return from(revenueData.getRevenueByOffstreetZone(action.payload)).pipe(
        map((x) => selectedOffstreetZonesActions.fetchRevenueSuccess({ zoneId: action.payload, revenue: x })),
        catchError((err) => of(selectedOffstreetZonesActions.fetchRevenueFailed(err.message))),
      );
    }),
  );
};

function findZoneFeature(zoneId: number): Feature<Geometry, GeoJsonProperties> | null {
  const state = store.getState();
  return state.offstreetZonesGeo.data.features.find((x) => x.properties?.id === zoneId) || null;
}

export const offstreetZonesEpic = combineEpics<AnyAction>(
  fetchOffstreetZonesEpic,
  citySelectedEpic,
  fetchVendorsEpic,
  offstreetZoneSelectedEpic,
  moveToZoneEpic,
  closePopupsEpic,
  fetchCount,
  fetchRevenueReportEpic,
  fetchRevenueEpic,
);
