import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';
import { ISelectedArea } from '../../../../model';
import { RootState } from '../../../../store';

export interface ISelectedAreaState {
  openPopup: boolean;
  position: Position | null;
  loading: boolean;
  area: ISelectedArea | null;
}

const initialState: ISelectedAreaState = {
  openPopup: false,
  position: null,
  loading: false,
  area: null,
};

const slice = createSlice({
  name: 'selectedArea',
  initialState: initialState,
  reducers: {
    loadArea(state, action: PayloadAction<{ id: number; position: Position | null }>) {
      state.loading = true;
      state.openPopup = true;
      state.position = action.payload.position;
      state.area = state.area?.Id === action.payload.id ? state.area : null;
    },
    loadAreaSuccess(state, action: PayloadAction<{ area: ISelectedArea; position: Position }>) {
      state.loading = false;
      state.area = action.payload.area;
      state.position = action.payload.position;
    },
    loadAreaFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      console.log(action);
    },
    closePopup(state) {
      state.openPopup = false;
    },
    openPopup(state) {
      state.openPopup = true;
    },
    moveToArea(state, action: PayloadAction<{ id: number; position: Position | null }>) {},
    moveToAreaFailed(state, action: PayloadAction<string>) {},
  },
});

// Actions
export const selectedAreaActions = slice.actions;

// Selectors
export const selectedAreaOpenPopup = (state: RootState) => state.selectedArea.openPopup;
export const selectedArea = (state: RootState) => state.selectedArea;

// Reducer
export const selectedAreaReducer = slice.reducer;
