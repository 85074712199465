import { Group } from '@progress/kendo-drawing';
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisLabels,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import classNames from 'classnames/bind';
import { FC, useMemo } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { IDayItem, ISpotOccupancyReport } from '../../../../model';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';
import { dateUtils, numberUtils } from '../../../../utils';
import { ReportPeriodSelector } from '../../common';
import { PeriodTextInfo } from '../../common/period-info/period-text-info/PeriodTextInfo';
import styles from './CurbSpaceOccupancySelector.module.scss';

interface IProps {
  report?: ISpotOccupancyReport | null;
  onChangeFilter?: (change: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => void;
}

const cx = classNames.bind(styles);

const calcMaxOccRate = (occupancySeries: IDayItem[]) => {
  const max = Math.max(...occupancySeries.map((x) => x.Value));
  return Math.min(max + 5, 100);
};

export const CurbSpaceOccupancySelector: FC<IProps> = ({ report, onChangeFilter }) => {
  const localization = useExtendedLocalization();

  const chart = useMemo(
    () =>
      report && (
        <Chart>
          <ChartValueAxis>
            <ChartValueAxisItem name={'occupancy'} min={0} max={calcMaxOccRate(report.OccupancyDayChartData)} labels={{ format: `{0}%` }} />
          </ChartValueAxis>

          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              title={{ text: localization.toLanguageStringF('spotOccupancy.popupTab.days') }}
              categories={report.OccupancyDayChartData.map((x) => x.Day)}
              axisCrossingValue={[0, 24]}
            >
              <ChartCategoryAxisLabels
                visual={(x) => ((x.value % 5 === 0 && x.value !== 30) || x.value === 1 || x.value === 31 ? x.createVisual() : new Group())}
              ></ChartCategoryAxisLabels>
            </ChartCategoryAxisItem>
          </ChartCategoryAxis>

          <ChartSeries>
            <ChartSeriesItem
              type='column'
              markers={{ visible: false }}
              data={report.OccupancyDayChartData.map((x) => x.Value)}
              axis={'occupancy'}
            />
          </ChartSeries>
        </Chart>
      ),
    [localization, report],
  );

  const content = useMemo(() => {
    const onChangeFilterHandler = (filter: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => {
      if (onChangeFilter) {
        onChangeFilter(filter);
      }
    };

    return (
      <>
        <ReportPeriodSelector onChangeFilter={onChangeFilterHandler} />

        {report && (
          <div className={cx('container')}>
            <div className={cx('column')}>
              <div className={cx('row')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('spotOccupancy.popupTab.rate')}
                  value={`${numberUtils.toUiString(report.OccupancyPercentage ?? 0)}%`}
                  prevValue={`${numberUtils.toUiString(report.OccupancyPrevPercentage ?? 0)}%`}
                  change={report.OccupancyDiffPercentage ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
              <div className={cx('row')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('spotOccupancy.popupTab.avTurnoverRate')}
                  value={`${numberUtils.toUiString(report.Turnover ?? 0)}`}
                  prevValue={`${numberUtils.toUiString(report.PrevTurnover ?? 0)}`}
                  change={report.DiffTurnover ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
              <div className={cx('row')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('spotOccupancy.popupTab.avDwellTime')}
                  value={dateUtils.formatMinutes(report.DwellTime ?? 0)}
                  prevValue={dateUtils.formatMinutes(report.PrevDwellTime ?? 0)}
                  change={report.DiffDwellTime ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
            </div>
            <div className={cx('column', 'chart')}>
              <div className={cx('row')}>{chart}</div>
            </div>
          </div>
        )}
      </>
    );
  }, [report, localization, chart, onChangeFilter]);

  return content;
};
