import { Feature, Point, GeoJsonProperties, Position, Geometry } from 'geojson';

type ItemType<TId extends number | string> = { id: TId; position: Position | null; initPosition: Position | null };

type SelectedEntitiesState = {
  [key: string]: Array<ItemType<string>>;
};

export enum SelectedEntityName {
  Cameras = 'cameras',
  CurbSpaces = 'curbSpaces',
  //Enforcements = 'enforcements',
  LoadingZones = 'loadingZones',
  Meters = 'meters',
  OffstreetZones = 'offstreetZones',
  //ParkingDurations = 'parkingDurations',
  RevenueOffstreetZones = 'revenueOffstreetZones',
  //RevenueZones = 'revenueZones',
  Heatmap = 'heatmap',
  Signs = 'signs',
  Zones = 'zones',
  RppZones = 'rppZones',
  RppAreas = 'rppAreas',
  StreetSigns = 'streetSigns',
  ParkingLots = 'parkingLots',
  CustomLayers = 'customLayers',
  Blockfaces = 'blockfaces',
  BlockfacesHeatmap = 'blockfacesHeatmap',
  StudyAreas = 'studyAreas',
  StudyAreasHeatmap = 'studyAreasHeatmap',
}

export interface ISelectedEntity<T, TId = number, TGeometry extends Geometry = Point> {
  id: TId;
  openPopup: boolean;
  pinnedPopup: boolean;
  position: Position | null;
  feature: Feature<TGeometry, GeoJsonProperties> | null;
  loading: boolean;
  entity: T | null;
  initPosition: Position | null;
}

const STATE_KEY = 'SELECTED_ENTITIES_STATE';
let _state: string | null = null;

function saveState<T, TId extends number | string, TGeometry extends Geometry>(
  entity: SelectedEntityName,
  selected: Array<ISelectedEntity<T, TId, TGeometry>>,
): void {
  const items = selected
    .filter((x) => x.pinnedPopup)
    .map((x) => ({ id: x.id.toString(), position: x.position, initPosition: x.initPosition }));

  const state = getState();
  state[entity] = items;

  const serialized = JSON.stringify(state);
  localStorage.setItem(STATE_KEY, serialized);
  _state = serialized;
}

function getState(): SelectedEntitiesState {
  if (!_state) _state = localStorage.getItem(STATE_KEY);

  if (_state) {
    try {
      return JSON.parse(_state);
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  return {};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getInitialState<T, TId extends number | string>(
  entity: SelectedEntityName,
  initialState: T,
  idConverter: (id: string) => TId,
  dispatch: (items: Array<ItemType<TId>>) => void,
): T {
  const selectedState = selectedEntitiesStateSerivce.getState();
  const items: Array<ItemType<TId>> = (selectedState[entity] || []).map((x) => ({
    id: idConverter(x.id),
    position: x.position,
    initPosition: x.initPosition,
  }));
  const selected = items.map((x) => ({
    id: x.id,
    openPopup: true,
    pinnedPopup: true,
    loading: true,
    entity: null,
    position: x.position,
    initPosition: x.initPosition,
  }));

  setTimeout(() => dispatch(items), 2000);

  return { ...initialState, ...{ selected: selected } };
}

export const selectedEntitiesStateSerivce = {
  saveState,
  getState,
  getInitialState,
};
