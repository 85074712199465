import { getApi } from './api';
import { IAreaType, ILookupValue, IPolicy, IZone, ZoneType } from '../../model';
import { Utils } from '../../utils';

const BASE_URL = '/zone';

const getZone = (id: number): Promise<IZone> => {
  return getApi()
    .get<IZone>(`${BASE_URL}/${id}`)
    .then((response) => response.data);
};

const getAreaZoneNames = (areaId: number): Promise<Array<ILookupValue>> => {
  return getApi()
    .get<Array<IAreaType>>(`${BASE_URL}/by-area/?id=${areaId}`)
    .then((response) => response.data || [])
    .then((items) => {
      items.forEach((v) => (v.Name = Utils.capitalize(v.Name)));
      return items;
    });
};

const getCount = (type: ZoneType = ZoneType.Regular): Promise<number> => {
  return getApi()
    .get<{ Count: number }>(`${BASE_URL}/count?type=${type}`)
    .then((response) => response.data.Count);
};

const getStudyAreaZoneNames = (studyAreaId: number) => {
  return getApi()
    .get<ILookupValue[]>(`${BASE_URL}/by-study-area/?id=${studyAreaId}`)
    .then((response) => response.data || []);
};

const getPolicies = (zoneId: number): Promise<IPolicy[]> => {
  return getApi()
    .get<IPolicy[]>(`${BASE_URL}/${zoneId}/policy`)
    .then((response) => response.data || []);
};

export const zones = {
  get: getZone,
  getAreaZoneNames: getAreaZoneNames,
  getCount: getCount,
  getStudyAreaZoneNames,
  getPolicies,
};
