import { AxiosError } from 'axios';
import {
  ICityOverviewReport,
  IOffstreetZoneRevenueReportItem,
  IParkingDurationReportItem,
  ITrafficReportItem,
  IViolationInfo,
  IViolationMetric,
  IZoneRevenueReportItem,
  Interval,
  Period,
  ViolationMetricPeriod,
} from '../../model';
import { IOccupancyReportItem, IOccupancyTrafficReport, ISpotOccupancyReport } from '../../model/api/occupancy';
import { IKpiReportItem } from '../../model/api/zone';
import { dateUtils } from '../../utils';
import { getApi } from './api';

const BASE_URL = '/report';
const allowedErrors = [404];

export enum PeriodTypeNumeric {
  Day = 1,
  Week = 2,
  Month = 3,
  Quarter = 4,
  Yesterday = 5,
}

export interface IReportFilter {
  period: [Date, Date];
  prevPeriod: [Date, Date];
  interval?: Interval;
}

const filterToQueryStr = (filter: IReportFilter): string => {
  return `period.from=${dateUtils.toDateTimeString(filter.period[0])}&period.to=${dateUtils.toDateTimeString(
    filter.period[1],
  )}&prevperiod.from=${dateUtils.toDateTimeString(filter.prevPeriod[0])}&prevperiod.to=${dateUtils.toDateTimeString(filter.prevPeriod[1])}${
    filter.interval ? `&interval=${filter.interval}` : ''
  }`;
};

const getZoneRevenueReport = (zoneId: number, filter: IReportFilter): Promise<IZoneRevenueReportItem | null> => {
  return getApi()
    .get<IZoneRevenueReportItem>(`${BASE_URL}/zone-revenue-custom/${zoneId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getZoneOccupancyTrafficReport = (zoneId: number, filter: IReportFilter): Promise<IOccupancyTrafficReport | null> =>
  getApi()
    .get<IOccupancyTrafficReport>(`${BASE_URL}/zone-occupancy-traffic/${zoneId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));

const getBlockfaceOccupancyTrafficReport = (blockfaceId: number, filter: IReportFilter): Promise<IOccupancyTrafficReport | null> =>
  getApi()
    .get<IOccupancyTrafficReport>(`${BASE_URL}/blockface-occupancy-traffic/${blockfaceId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));

const getZoneKpiReport = (zoneId: number): Promise<Array<IKpiReportItem>> => {
  return getApi()
    .get<Array<IKpiReportItem>>(`${BASE_URL}/zone-kpi/${zoneId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, PeriodId: PeriodTypeNumeric[x.PeriodId as unknown as number] as Period };
      }),
    );
};

const getOffstreetZoneRevenueReport = (zoneId: number, filter: IReportFilter): Promise<IOffstreetZoneRevenueReportItem | null> => {
  return getApi()
    .get<IOffstreetZoneRevenueReportItem>(`${BASE_URL}/offstreet-zone-revenue/${zoneId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getCityOverviewReport = (): Promise<ICityOverviewReport | null> => {
  return getApi({ ignoreErrorStatuses: allowedErrors })
    .get<ICityOverviewReport>(`${BASE_URL}/city-overview`)
    .then((res) => {
      res.data.DailyRevenueChartData.forEach((x) => {
        x.Date = new Date(x.Date);
      });

      return res.data;
    })
    .catch((err: AxiosError) => {
      if (allowedErrors.includes(err.response?.status || 0)) {
        return null;
      }
      throw err;
    });
};

const getParkingDurationReport = (zoneId: number, filter: IReportFilter): Promise<IParkingDurationReportItem | null> => {
  return getApi()
    .get<IParkingDurationReportItem>(`${BASE_URL}/zone-parking-duration/${zoneId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getZoneEnforcementReport = (zoneId: number, filter: IReportFilter): Promise<IViolationInfo | null> =>
  getApi({ ignoreErrorStatuses: allowedErrors })
    .get<IViolationInfo>(`${BASE_URL}/zone-enforcement/${zoneId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null))
    .catch((err: AxiosError) => {
      if (allowedErrors.includes(err.response?.status || 0)) {
        return null;
      }
      throw err;
    });

const getBlockfaceEnforcementReport = (blockfaceId: number, filter: IReportFilter): Promise<IViolationInfo | null> =>
  getApi({ ignoreErrorStatuses: allowedErrors })
    .get<IViolationInfo>(`${BASE_URL}/blockface-enforcement/${blockfaceId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null))
    .catch((err: AxiosError) => {
      if (allowedErrors.includes(err.response?.status || 0)) {
        return null;
      }
      throw err;
    });

const getStudyAreaEnforcementReport = (studyAreaId: number, filter: IReportFilter): Promise<IViolationInfo | null> =>
  getApi({ ignoreErrorStatuses: allowedErrors })
    .get<IViolationInfo>(`${BASE_URL}/study-area-enforcement/${studyAreaId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null))
    .catch((err: AxiosError) => {
      if (allowedErrors.includes(err.response?.status || 0)) {
        return null;
      }
      throw err;
    });

const getZoneMetricReport = (zoneId: number): Promise<IViolationMetric[]> =>
  getApi({ ignoreErrorStatuses: allowedErrors })
    .get<IViolationMetric[]>(`${BASE_URL}/zone-enforcement-metric/${zoneId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return {
          ...x,
          Period: PeriodTypeNumeric[x.Period as unknown as number] as ViolationMetricPeriod,
        };
      }),
    )
    .catch((err: AxiosError) => {
      if (allowedErrors.includes(err.response?.status || 0)) {
        return [];
      }
      throw err;
    });

const getBlockfaceRevenueReport = (blockfaceId: number, filter: IReportFilter): Promise<IZoneRevenueReportItem | null> => {
  return getApi()
    .get<IZoneRevenueReportItem>(`${BASE_URL}/blockface-revenue/${blockfaceId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getZoneTrafficReport = (zoneId: number): Promise<Array<ITrafficReportItem>> => {
  return getApi()
    .get<Array<ITrafficReportItem>>(`${BASE_URL}/zone-traffic/${zoneId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );
};

const getBlockfaceTrafficReport = (blockfaceId: number): Promise<Array<ITrafficReportItem>> => {
  return getApi()
    .get<Array<ITrafficReportItem>>(`${BASE_URL}/blockface-traffic/${blockfaceId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );
};

const getSpotOccupancyReport = (spotId: number): Promise<Array<IOccupancyReportItem>> =>
  getApi()
    .get<Array<IOccupancyReportItem>>(`${BASE_URL}/spot-occupancy/${spotId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );

const getSpotOccupancyCustomPeriodReport = (spotId: number, filter: IReportFilter): Promise<ISpotOccupancyReport | null> =>
  getApi()
    .get<ISpotOccupancyReport>(`${BASE_URL}/spot-occupancy-custom-period/${spotId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));

const getZoneOccupancyReport = (spotId: number): Promise<Array<IOccupancyReportItem>> =>
  getApi()
    .get<Array<IOccupancyReportItem>>(`${BASE_URL}/zone-occupancy/${spotId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );

const getBlockfaceOccupancyReport = (spotId: number): Promise<Array<IOccupancyReportItem>> =>
  getApi()
    .get<Array<IOccupancyReportItem>>(`${BASE_URL}/blockface-occupancy/${spotId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );

const getBlockfaceParkingDurationReport = (blockfaceId: number, filter: IReportFilter): Promise<IParkingDurationReportItem | null> => {
  return getApi()
    .get<IParkingDurationReportItem>(`${BASE_URL}/blockface-parking-duration/${blockfaceId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getStudyAreaParkingDurationReport = (studyAreaId: number, filter: IReportFilter): Promise<IParkingDurationReportItem | null> => {
  return getApi()
    .get<IParkingDurationReportItem>(`${BASE_URL}/study-area-parking-duration/${studyAreaId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getStudyAreaRevenueReport = (studyAreaId: number, filter: IReportFilter): Promise<IZoneRevenueReportItem | null> => {
  return getApi()
    .get<IZoneRevenueReportItem>(`${BASE_URL}/study-area-revenue/${studyAreaId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));
};

const getStudyAreaOccupancyTrafficReport = (studyAreaId: number, filter: IReportFilter): Promise<IOccupancyTrafficReport | null> =>
  getApi()
    .get<IOccupancyTrafficReport>(`${BASE_URL}/study-area-occupancy-traffic/${studyAreaId}?${filterToQueryStr(filter)}`)
    .then((response) => (response.status === 200 ? response.data : null));

const getStudyAreaTrafficReport = (studyAreaId: number): Promise<Array<ITrafficReportItem>> =>
  getApi()
    .get<Array<ITrafficReportItem>>(`${BASE_URL}/study-area-traffic/${studyAreaId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );

const getStudyAreaOccupancyReport = (studyAreaId: number): Promise<Array<IOccupancyReportItem>> =>
  getApi()
    .get<Array<IOccupancyReportItem>>(`${BASE_URL}/study-area-occupancy/${studyAreaId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, Period: PeriodTypeNumeric[x.Period as unknown as number] as Period };
      }),
    );

const getBlockfaceKpiReport = (blockfaceId: number): Promise<Array<IKpiReportItem>> =>
  getApi()
    .get<Array<IKpiReportItem>>(`${BASE_URL}/blockface-kpi/${blockfaceId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, PeriodId: PeriodTypeNumeric[x.PeriodId as unknown as number] as Period };
      }),
    );

const getStudyAreaKpiReport = (studyAreaId: number): Promise<Array<IKpiReportItem>> =>
  getApi()
    .get<Array<IKpiReportItem>>(`${BASE_URL}/study-area-kpi/${studyAreaId}`)
    .then((response) =>
      (response.data || []).map((x) => {
        return { ...x, PeriodId: PeriodTypeNumeric[x.PeriodId as unknown as number] as Period };
      }),
    );

export const reports = {
  getZoneOccupancyTrafficReport,
  getBlockfaceOccupancyTrafficReport,
  getZoneRevenueReport,
  getZoneKpiReport,
  getOffstreetZoneRevenueReport,
  getCityOverviewReport,
  getParkingDurationReport,
  getZoneEnforcementReport,
  getBlockfaceEnforcementReport,
  getStudyAreaEnforcementReport,
  getZoneMetricReport,
  getBlockfaceRevenueReport,
  getZoneTrafficReport,
  getBlockfaceTrafficReport,
  getBlockfaceParkingDurationReport,
  getSpotOccupancyReport,
  getSpotOccupancyCustomPeriodReport,
  getZoneOccupancyReport,
  getBlockfaceOccupancyReport,
  getStudyAreaRevenueReport,
  getStudyAreaOccupancyTrafficReport,
  getStudyAreaTrafficReport,
  getStudyAreaOccupancyReport,
  getBlockfaceKpiReport,
  getStudyAreaKpiReport,
  getStudyAreaParkingDurationReport,
};
