import { IPublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { FunctionComponent, lazy, useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import { HashRouter, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { init as amplitudeInit } from '@amplitude/analytics-browser';
import { init as fsInit, isInitialized as fsIsInitialized } from '@fullstory/browser';

import { store } from './store';
import { useAppDispatch, useAppSelector } from './store/hooks';

import Layout from './components/layout/Layout';

import { appSettingsActions, selectAppSettings } from './features/common/app-settings/app-settings-slice';
import createMsalInstance from './features/common/auth/msal-instance';

import AuthenticatedRoute from './components/auth/AuthenticatedRoute';
import { citiesActions, globalizationLocale } from './features';
import { selectUserProfile, userProfileActions } from './features/common/auth/auth-slice';
import { CustomNavigationClient } from './utils/NavigationClient';

import { IntlProvider, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { ExtendedLocalizationService } from './features/common/globalization/extended-localization-service';
import { ExtendedLocalizationContext } from './hooks/use-extended-localization-service';
import Forecasting from './pages/forecasting/Forecasting';
import enMessages from './assets/messages/en-US.json';
import { ConfirmationServiceProvider } from './components/common';
import { UserProfile } from './model';
import AuthorizedRoute from './components/auth/AuthorizedRoute';
import LoadingZones from './pages/loading-zones/LoadingZones';
import { amplitudeService, AmplitudeEvent } from './services';

const NoAccess = lazy(() => import('./pages/no-access/NoAccess'));
const Map = lazy(() => import('./pages/map/Map'));
const Reports = lazy(() => import('./pages/reports/Reports'));
const Login = lazy(() => import('./pages/login/Login'));

const LocalizationWrapper: FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  loadMessages(enMessages, 'en-US');
  const locale = useAppSelector(globalizationLocale);
  return (
    <IntlProvider locale={locale}>
      <LocalizationProvider language={locale}>
        <ExtendedLocalizationContext.Provider value={new ExtendedLocalizationService()}>{children}</ExtendedLocalizationContext.Provider>
      </LocalizationProvider>
    </IntlProvider>
  );
};

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <LocalizationWrapper>
        <HashRouter>
          <App />
        </HashRouter>
      </LocalizationWrapper>
    </Provider>
  );
};

const ReportsRoute = '/reports';
const ForecastRoute = '/forecast';

const App = () => {
  const msalRef = useRef<IPublicClientApplication | null>(null);
  const [msal, setMsal] = useState<IPublicClientApplication | null>(null);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const settings = useAppSelector(selectAppSettings);
  const profile = useAppSelector(selectUserProfile);
  const location = useLocation();

  useEffect(() => {
    dispatch(appSettingsActions.fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (profile) {
      dispatch(citiesActions.fetchCities());
    }
  }, [dispatch, profile]);

  useEffect(() => {
    if (settings && !msalRef.current) {
      const msalInstance = createMsalInstance(settings, (account) => {
        if (account) {
          const userProfile = new UserProfile(account.username, account.name || '', account.idTokenClaims?.roles || []);
          dispatch(userProfileActions.setProfile(userProfile));
          amplitudeService.setUser(userProfile.username);
        }
      });

      const navigationClient = new CustomNavigationClient(history);
      msalInstance.setNavigationClient(navigationClient);
      msalRef.current = msalInstance;
      setMsal(msalInstance);
    }
  }, [dispatch, history, settings]);

  useEffect(() => {
    const path = location.pathname.toLowerCase();
    if (path === ReportsRoute) {
      amplitudeService.track(AmplitudeEvent.ReportPageOpen);
    } else if (path === ForecastRoute) {
      amplitudeService.track(AmplitudeEvent.ForecastPageOpen);
    }
  }, [location]);

  if (!settings || !msal) {
    return <div></div>;
  }

  if (settings.amplitude?.apiKey) {
    amplitudeInit(settings.amplitude.apiKey);
  }

  if (settings.fullStory?.orgId && !fsIsInitialized()) {
    fsInit({ orgId: settings.fullStory.orgId });
  }

  return (
    <MsalProvider instance={msal}>
      <ConfirmationServiceProvider>
        <Layout>
          <Routes>
            <Route element={<AuthenticatedRoute requireAuth={false} redirectPath='/map' />}>
              <Route path='/login' element={<Login />} />

              <Route path='*' element={<Navigate to='/login' replace />} />
            </Route>

            <Route element={<AuthenticatedRoute requireAuth={true} redirectPath='/login' />}>
              <Route element={<AuthorizedRoute requireAuth={false} redirectPath='map' />}>
                <Route path='/no-access' element={<NoAccess />} />
                <Route path='*' element={<Navigate to='/no-access' replace />} />
              </Route>

              <Route element={<AuthorizedRoute requireAuth={true} redirectPath='/no-access' />}>
                <Route path='/map' element={<Map />} />
                <Route path={`${ReportsRoute}/*`} element={<Reports />} />
                <Route path={`${ForecastRoute}/*`} element={<Forecasting />} />
                <Route path='/loading-zones/*' element={<LoadingZones />} />

                <Route path='*' element={<Navigate to='/map' replace />} />
              </Route>
            </Route>
          </Routes>
        </Layout>
      </ConfirmationServiceProvider>

      <ToastContainer autoClose={2000} position='bottom-right' />
    </MsalProvider>
  );
};

export default AppWrapper;
