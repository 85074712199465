import { IRevenueItem, Period, WeekDay } from '../../model';
import { dateUtils } from '../../utils';
import { getApi } from './api';
import { PeriodTypeNumeric } from './reports';

const BASE_URL = '/Revenue';

export interface IRevenueHeatmapData {
  Id: number;
  Revenue: number;
  NormalizedRevenue: number;
  AverageRevenue: number;
  NormalizedAverageRevenue: number;
}

const getRevenueByArea = (areaId: number): Promise<Array<IRevenueItem>> => {
  return getApi()
    .get<Array<IRevenueItem>>(`${BASE_URL}/by-area?id=${areaId}`)
    .then((response) => response.data || []);
};

const getRevenueByZone = (zoneId: number): Promise<Array<IRevenueItem>> => {
  return getApi()
    .get<Array<IRevenueItem>>(`${BASE_URL}/by-zone?id=${zoneId}`)
    .then((response) =>
      (response.data || []).map((x) => ({
        ...x,
        Period: PeriodTypeNumeric[x.Period as unknown as number] as Period,
      })),
    );
};

const getRevenueByBlockface = (blockfaceId: number): Promise<Array<IRevenueItem>> => {
  return getApi()
    .get<Array<IRevenueItem>>(`${BASE_URL}/by-blockface?id=${blockfaceId}`)
    .then((response) =>
      (response.data || []).map((x) => ({
        ...x,
        Period: PeriodTypeNumeric[x.Period as unknown as number] as Period,
      })),
    );
};

const getRevenueByStudyArea = (studyAreaId: number): Promise<Array<IRevenueItem>> => {
  return getApi()
    .get<Array<IRevenueItem>>(`${BASE_URL}/by-study-area?id=${studyAreaId}`)
    .then((response) =>
      (response.data || []).map((x) => ({
        ...x,
        Period: PeriodTypeNumeric[x.Period as unknown as number] as Period,
      })),
    );
};

const getRevenueByMeter = (meterId: number): Promise<Array<IRevenueItem>> => {
  return getApi()
    .get<Array<IRevenueItem>>(`${BASE_URL}/by-meter?id=${meterId}`)
    .then((response) =>
      (response.data || []).map((x) => ({
        ...x,
        Period: PeriodTypeNumeric[x.Period as unknown as number] as Period,
      })),
    );
};

const getRevenueByOffstreetZone = (offstreetZoneId: number): Promise<Array<IRevenueItem>> => {
  return getApi()
    .get<Array<IRevenueItem>>(`${BASE_URL}/by-offstreetZone?id=${offstreetZoneId}`)
    .then((response) =>
      (response.data || []).map((x) => ({
        ...x,
        Period: PeriodTypeNumeric[x.Period as unknown as number] as Period,
      })),
    );
};

const getStudyAreaRevenueHeatmapData = (
  period: [Date, Date],
  weekDays: WeekDay[],
  minutesStart: number,
  minutesEnd: number,
): Promise<Array<IRevenueHeatmapData>> => {
  const weekDaysParam = weekDays.map((x) => `weekDays=${x}`).join('&');
  return getApi()
    .get<Array<IRevenueHeatmapData>>(
      `${BASE_URL}/study-area/heatmap-data?period.from=${dateUtils.toDateString(period[0])}&period.to=${dateUtils.toDateString(
        period[1],
      )}&${weekDaysParam}&time.from=${minutesStart}&time.to=${minutesEnd}`,
    )
    .then((response) => response.data || []);
};

export const revenueData = {
  getRevenueByArea,
  getRevenueByZone,
  getRevenueByBlockface,
  getRevenueByStudyArea,
  getRevenueByMeter,
  getRevenueByOffstreetZone,
  getStudyAreaRevenueHeatmapData,
};
