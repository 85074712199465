import { IParkingLot } from '../../model/api';
import { getApi } from './api';

const BASE_URL = '/parkingLot';

const get = (id: number): Promise<IParkingLot> => {
  return getApi()
    .get<IParkingLot>(`${BASE_URL}/${id}`)
    .then((response) => response.data);
};

const getCount = (): Promise<number> => {
  return getApi()
    .get<{ Count: number }>(`${BASE_URL}/count`)
    .then((response) => response.data.Count);
};

export const parkingLots = {
  get,
  getCount,
};
