import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Position } from 'geojson';

import { RootState, store } from '../../../../store';
import { IReportFilter, ISelectedEntity, selectedEntitiesStateSerivce, SelectedEntityName } from '../../../../services';
import { selectedSliceLogic } from '../selected-slice-logic';
import {
  IOccupancyTrafficReport,
  IParkingDurationReportItem,
  ISelectedBlockfaceHeatmap,
  IViolationInfo,
  IZoneRevenueReportItem,
} from '../../../../model';

export interface ISelectedBlockfaceHeatmapState {
  selected: ISelectedEntity<ISelectedBlockfaceHeatmap>[];
  current: number | null;
}

const initialState: ISelectedBlockfaceHeatmapState = {
  selected: [],
  current: null,
};

const slice = createSlice({
  name: 'selectedRevenueBlockfaces',
  initialState: initialState,
  reducers: {
    init(state) {
      state.selected = selectedEntitiesStateSerivce.getInitialState(
        SelectedEntityName.BlockfacesHeatmap,
        initialState,
        (id) => parseInt(id),
        (selected) => {
          selected.map((x) => store.dispatch(selectedBlockfaceHeatmapsActions.loadHeatmap(x)));
        },
      ).selected;
    },
    change(state, action: PayloadAction<{ id: number; position?: Position }>) {
      selectedSliceLogic.handleChanges(state, action.payload.id, SelectedEntityName.BlockfacesHeatmap, action.payload.position);
    },
    collapsePopups(state) {
      selectedSliceLogic.collapsePopups(state, SelectedEntityName.BlockfacesHeatmap);
    },
    loadHeatmap(state, action: PayloadAction<{ id: number; position: Position | null; initPosition?: Position | null }>) {
      selectedSliceLogic.handleLoad(state, action.payload.id, action.payload.position, null, null, action.payload.initPosition);
    },
    loadHeatmapSuccess(state, action: PayloadAction<{ heatmap: ISelectedBlockfaceHeatmap; position: Position; initPosition?: Position }>) {
      selectedSliceLogic.handleLoadSuccess(
        state,
        action.payload.heatmap.blockface.Id,
        action.payload.heatmap,
        action.payload.position,
        action.payload.initPosition,
      );
    },
    loadHeatmapFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      selectedSliceLogic.handleLoadFail(state, action.payload.id);
      console.log(action.payload);
    },

    fetchDurationReport(state, action: PayloadAction<{ id: number; filter: IReportFilter }>) {
      const item = state.selected.find((x) => x.entity?.blockface.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchDurationReportSuccess(state, action: PayloadAction<{ id: number; report: IParkingDurationReportItem | null }>) {
      const item = state.selected.find((x) => x.entity?.blockface.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = false;

      if (!item.entity) {
        return;
      }
      item.entity.durationReport = action.payload.report;
    },
    fetchReportFailed(state, action: PayloadAction<{ id: number; error: string }>) {
      const item = state.selected.find((x) => x.entity?.blockface.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = false;
      console.error(action.payload.error);
    },

    fetchViolationReport(state, action: PayloadAction<{ blockfaceId: number; filter: IReportFilter }>) {
      const blockface = state.selected.find((x) => x.entity?.blockface.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = true;
    },
    fetchViolationReportSuccess(state, action: PayloadAction<{ blockfaceId: number; report: IViolationInfo | null }>) {
      const blockface = state.selected.find((x) => x.entity?.blockface.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = false;
      if (!blockface.entity) {
        return;
      }
      blockface.entity.enforcementReport = action.payload.report;
    },
    fetchViolationReportFailed(state, action: PayloadAction<{ blockfaceId: number; error: string }>) {
      const blockface = state.selected.find((x) => x.entity?.blockface.Id === action.payload.blockfaceId);
      if (!blockface) {
        return;
      }
      blockface.loading = false;
      console.error(action.payload.error);
    },

    fetchRevenueReport(state, action: PayloadAction<{ id: number; filter: IReportFilter }>) {
      const item = state.selected.find((x) => x.entity?.blockface.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchRevenueReportSuccess(state, action: PayloadAction<{ id: number; report: IZoneRevenueReportItem | null }>) {
      const item = state.selected.find((x) => x.entity?.blockface.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = false;

      if (!item.entity) {
        return;
      }
      item.entity.revenueReport = action.payload.report;
    },

    fetchOccupancyTrafficReport(state, action: PayloadAction<{ id: number; filter: IReportFilter }>) {
      const item = state.selected.find((x) => x.entity?.blockface?.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = true;
    },
    fetchOccupancyTrafficReportSuccess(state, action: PayloadAction<{ id: number; report: IOccupancyTrafficReport | null }>) {
      const item = state.selected.find((x) => x.entity?.blockface?.Id === action.payload.id);
      if (!item) {
        return;
      }
      item.loading = false;
      if (!item.entity) {
        return;
      }
      item.entity.occupancyTrafficReport = action.payload.report;
    },

    closePopup(state, action: PayloadAction<number | undefined>) {
      selectedSliceLogic.closePopup(state, SelectedEntityName.BlockfacesHeatmap, action.payload);
    },
    openPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.openPopup(state, action.payload);
    },
    pinPopup(state, action: PayloadAction<number>) {
      selectedSliceLogic.pinPopup(state, SelectedEntityName.BlockfacesHeatmap, action.payload);
    },
  },
});

// Actions
export const selectedBlockfaceHeatmapsActions = slice.actions;

// Selectors
export const selectedBlockfaceHeatmaps = (state: RootState) => state.selectedBlockfaceHeatmaps;

export const currentBlockfaceHeatmap = (state: RootState) =>
  state.selectedBlockfaceHeatmaps.selected.find((x) => x.id === state.selectedBlockfaceHeatmaps.current);

// Reducer
export const selectedBlockfaceHeatmapsReducer = slice.reducer;
