import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICity } from '../../../model';
import { RootState } from '../../../store';

export interface IViewState {
  longitude: number;
  latitude: number;
  zoom: number;
  pitch: number;
}

export interface ICitiesState {
  initialized: boolean;
  loading: boolean;
  cities: ICity[];
  selectedCity: ICity | null;
  viewState?: IViewState;
  hasPerformanceParkingMeters: boolean;
}

const initialState: ICitiesState = {
  initialized: false,
  loading: false,
  cities: [],
  selectedCity: null,
  hasPerformanceParkingMeters: false,
};

const slice = createSlice({
  name: 'cities',
  initialState: initialState,
  reducers: {
    fetchCities(state) {
      state.loading = true;
    },
    fetchCitiesSuccess(state, action: PayloadAction<ICity[]>) {
      state.loading = false;
      // state.selectedCity = getSavedCity(action.payload);
      state.cities = action.payload;
      state.initialized = true;
    },
    fetchCitiesFailed(state, action: PayloadAction<string>) {
      state.loading = false;
      state.initialized = true;
      console.log(action);
    },
    selectCity(state, action: PayloadAction<ICity | null>) {
      state.selectedCity = action.payload;
    },

    fetchViewState(state) {},
    setViewState(state, action: PayloadAction<IViewState | undefined>) {
      state.viewState = action.payload;
    },
    saveViewState(state, action: PayloadAction<IViewState>) {},

    fetchPerformanceParkingCount(state) {},
    fetchPerformanceParkingCountSuccess(state, action: PayloadAction<number>) {
      state.hasPerformanceParkingMeters = action.payload > 0;
    },
    fetchPerformanceParkingCountFailed(state, action: PayloadAction<string>) {
      state.hasPerformanceParkingMeters = initialState.hasPerformanceParkingMeters;
      console.log(action);
    },
  },
});

// Actions
export const citiesActions = slice.actions;

// Selectors
export const selectCitiesLoading = (state: RootState) => state.cities.loading;
export const selectCities = (state: RootState) => state.cities;
export const selectCity = (state: RootState) => state.cities.selectedCity;

export const cityViewState = (state: RootState) => state.cities.viewState;

// Reducer
export const citiesReducer = slice.reducer;
