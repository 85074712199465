/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useCallback, useMemo } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { ISelectedEntity, NavigationSource, PopupType, ReportName, amplitudeService } from '../../../../services';
import { ISelectedBlockface, SidebarName, SpotOccupancyCalculator } from '../../../../model';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useOccupancyTrafficEnabled, useOpenPopupDispatch, useReportBuilder } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import {
  currentBlockface,
  selectedBlockfacesActions,
  selectedCurbSpacesActions,
  selectedMetersActions,
  selectedStudyAreasActions,
  sidebarActions,
} from '../../../../features';
import { Link } from '../../../common';
import { MapSidebarContainer } from '../../controls';
import { OccupancyTable, SidebarSectionLazyLoader } from '../../common';
import { TrafficTable } from '../../common/traffic-table/TrafficTable';
import { ZoneKpiTable } from '../../common/zone-kpi-table/ZoneKpiTable';

import styles from './BlockfaceSidebar.module.scss';
import { RevenueTable } from '../../../revenue';

const SidebarRow: FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <div className='map-sidebar-row'>
    <label>{title}</label>
    <div>{children}</div>
  </div>
);

interface Props {
  blockfaceData: ISelectedEntity<ISelectedBlockface>;
}

const Sidebar: FC<Props> = ({ blockfaceData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const localization = useExtendedLocalization();
  const reportsBuilder = useReportBuilder();
  const blockface = blockfaceData.entity;
  const occupancyTrafficEnabled = useOccupancyTrafficEnabled();

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const mapViewClick = () => {
    blockface && popupDispatch(selectedBlockfacesActions.openPopup(blockface.Id));
  };

  const revenueReportUrl = useMemo(
    () => (blockface ? reportsBuilder.getBlockfaceRevenue(blockface.Id) : null),
    [blockface, reportsBuilder],
  );

  const revenueMoreInfo = useCallback(
    (ev: React.FormEvent) => {
      ev.preventDefault();
      if (revenueReportUrl) {
        window.open(revenueReportUrl, '_blank');
        amplitudeService.trackReport(ReportName.BlockfaceRevenue, NavigationSource.Sidebar);
      }
    },
    [revenueReportUrl],
  );

  const openMeter = useCallback(
    (meterId: number) => {
      popupDispatch(selectedMetersActions.loadMeter({ id: meterId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openSpot = useCallback(
    (spotId: number) => {
      popupDispatch(selectedCurbSpacesActions.load({ id: spotId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.CurbSpaces, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openStudyArea = useCallback(
    (studyAreaId: number) => {
      popupDispatch(selectedStudyAreasActions.loadStudyArea({ id: studyAreaId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  if (!blockface) {
    return null;
  }

  const spotsCount = SpotOccupancyCalculator.getCountSpotStatuses(
    blockface.spotsStates.map((x) => x.Status),
    blockface.SpotsCount,
  );

  return (
    <div className='map-sidebar map-sidebar map-sidebar-info'>
      <div className='map-sidebar-header'>
        <h3>Blockface: #{blockface?.Code}</h3>
        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>

      <div className='map-sidebar-body'>
        <PanelBar keepItemsMounted={true}>
          <PanelBarItem title={localization.toLanguageStringF('common.generalInfo')} expanded={true}>
            <div>
              {blockface.studyAreas.length > 0 && (
                <SidebarRow title={`${localization.toLanguageStringF('blockface.studyAreas')}:`}>
                  {blockface.studyAreas.map((x, idx) => (
                    <Link key={x.Id} onClick={() => openStudyArea(x.Id)} className={styles.listItem}>
                      <span>{x.Name}</span>
                      {idx < blockface.studyAreas.length - 1 && ','}
                    </Link>
                  ))}
                </SidebarRow>
              )}
              <SidebarRow title={`${localization.toLanguageStringF('blockface.sidebar.address')}:`}>{blockface.Address}</SidebarRow>
              <SidebarRow title='Vacant:'>{spotsCount.vacant}</SidebarRow>
              <SidebarRow title='Booked:'>{spotsCount.booked}</SidebarRow>
              <SidebarRow title='Occupied:'>{spotsCount.occupied}</SidebarRow>
              <SidebarRow title='Not Monitored:'>{spotsCount.notMonitored}</SidebarRow>
              <SidebarRow title={`${localization.toLanguageStringF('common.parkingSpacesCount')}:`}>{blockface.SpotsCount}</SidebarRow>
            </div>
            <div className='map-sidebar-row more-info'>
              <Link onClick={mapViewClick} className='more-info-button' disabled={blockfaceData.openPopup}>
                {localization.toLanguageStringF('common.mapView')}
              </Link>
            </div>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('blockface.revenue')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(blockface.revenue?.length || 0) > 0}
              shouldLoadData={blockface.revenue === undefined}
              loadData={() => dispatch(selectedBlockfacesActions.fetchRevenue(blockface.Id))}
            >
              <RevenueTable revenue={blockface.revenue || []} />
              <div className='map-sidebar-row more-info'>
                {revenueReportUrl && (
                  <Link onClick={revenueMoreInfo} className='more-info-button'>
                    {localization.toLanguageStringF('common.moreInfo')}
                  </Link>
                )}
              </div>
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('common.report.kpi')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(blockface.kpiReport?.length || 0) > 0}
              shouldLoadData={blockface.kpiReport === undefined}
              loadData={() => dispatch(selectedBlockfacesActions.fetchKpiReport(blockface.Id))}
            >
              <ZoneKpiTable kpiReport={blockface.kpiReport || []} className={styles.panelMargin} />
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('common.report.traffic')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(blockface.trafficReport?.length || 0) > 0}
              shouldLoadData={blockface.trafficReport === undefined}
              loadData={() => dispatch(selectedBlockfacesActions.fetchTrafficReport(blockface.Id))}
            >
              <TrafficTable report={blockface.trafficReport || []} className={styles.panelMargin} />
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          {occupancyTrafficEnabled && (
            <PanelBarItem title={localization.toLanguageStringF('common.report.occupancy')} expanded={false}>
              <SidebarSectionLazyLoader
                hasData={(blockface.occupancyReport?.length || 0) > 0}
                shouldLoadData={blockface.occupancyReport === undefined}
                loadData={() => dispatch(selectedBlockfacesActions.fetchOccupancyReport(blockface.Id))}
              >
                <OccupancyTable occupancyReport={blockface.occupancyReport || []} />
              </SidebarSectionLazyLoader>
            </PanelBarItem>
          )}

          {blockface.metersNames.length > 0 && (
            <PanelBarItem
              title={localization.toLanguageStringF('blockface.sidebar.meters')}
              expanded={false}
              className='panelbar-scrollable'
            >
              <div className={styles.wrapContainer}>
                {blockface.metersNames.map((x, idx) => (
                  <Link key={x.Id} onClick={() => openMeter(x.Id)} className={styles.linkButton}>
                    <span>{x.Name}</span>
                    {idx < blockface.metersNames.length - 1 && ','}
                  </Link>
                ))}
              </div>
            </PanelBarItem>
          )}

          {blockface.sensors.length > 0 && (
            <PanelBarItem
              title={localization.toLanguageStringF('blockface.sidebar.sensors')}
              expanded={false}
              className='panelbar-scrollable'
            >
              <div className={styles.wrapContainer}>
                {blockface.sensors.map((x, idx) => (
                  <Link key={x.SensorId} onClick={() => openSpot(x.SpotId)} className={styles.linkButton}>
                    <span>{x.SensorName}</span>
                    {idx < blockface.sensors.length - 1 && ','}
                  </Link>
                ))}
              </div>
            </PanelBarItem>
          )}
        </PanelBar>
      </div>
    </div>
  );
};

export const BlockfaceSidebar: FC = () => {
  const current = useAppSelector(currentBlockface);

  return current ? (
    <MapSidebarContainer name={SidebarName.blockface}>
      <Sidebar blockfaceData={current} />
    </MapSidebarContainer>
  ) : null;
};
