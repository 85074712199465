import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisLabels,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from '@progress/kendo-react-charts';
import classNames from 'classnames/bind';
import { FC, useMemo } from 'react';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { IDayItem, IHourItem, IOccupancyTrafficReport } from '../../../../model';
import { HeatmapPeriod } from '../../../../model/api/heatmap-period';
import { dateUtils, numberUtils } from '../../../../utils';
import { PeriodTextInfo } from '../period-info/period-text-info/PeriodTextInfo';
import { ReportPeriodSelector } from '../report-period-selector/ReportPeriodSelector';
import styles from './OccupancyTrafficSelector.module.scss';

const cx = classNames.bind(styles);

const calcMaxOccRate = (occupancySeries: IHourItem[] | IDayItem[]) => {
  const max = Math.max(...occupancySeries.map((x) => x.Value));
  return Math.min(max, 100) + 5;
};

const OccupancyTrafficChart: FC<{
  occupancySeries: IHourItem[] | IDayItem[];
  trafficSeries: IHourItem[] | IDayItem[];
  trafficMaxValue: number;
  category: string;
  categories: string[];
}> = ({ occupancySeries, trafficSeries, trafficMaxValue, category, categories }) => {
  const localization = useExtendedLocalization();

  const content = useMemo(() => {
    return (
      <Chart>
        <ChartValueAxis>
          <ChartValueAxisItem name={'occupancy'} min={0} max={calcMaxOccRate(occupancySeries)} labels={{ format: `{0}%` }} />
          <ChartValueAxisItem name={'traffic'} min={0} max={trafficMaxValue + 2} labels={{ format: `{0}mph` }}></ChartValueAxisItem>
        </ChartValueAxis>

        <ChartCategoryAxis>
          <ChartCategoryAxisItem title={{ text: category }} categories={categories} axisCrossingValue={[0, 24]}>
            <ChartCategoryAxisLabels rotation={-45}></ChartCategoryAxisLabels>
          </ChartCategoryAxisItem>
        </ChartCategoryAxis>

        <ChartSeries>
          <ChartSeriesItem
            name={localization.toLanguageStringF('occupancyVsTraffic.popupTab.groundSensor')}
            type='line'
            markers={{ visible: false }}
            data={occupancySeries.map((x) => x.Value)}
            axis={'occupancy'}
          />
          <ChartSeriesItem
            name={localization.toLanguageStringF('occupancyVsTraffic.popupTab.traffic')}
            type='line'
            markers={{ visible: false }}
            data={trafficSeries.map((x) => x.Value)}
            axis={'traffic'}
          />
        </ChartSeries>

        <ChartLegend position='bottom' orientation='horizontal' labels={{ font: '12px Segoe UI' }} />
      </Chart>
    );
  }, [categories, category, localization, occupancySeries, trafficMaxValue, trafficSeries]);

  return content;
};

interface IProps {
  report?: IOccupancyTrafficReport | null;
  onChangeFilter?: (change: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => void;
}

export const OccupancyTrafficSelector: FC<IProps> = ({ report, onChangeFilter }) => {
  const localization = useExtendedLocalization();

  const content = useMemo(() => {
    const onChangeFilterHandler = (filter: { heatmapPeriod: HeatmapPeriod; period: [Date, Date] }) => {
      if (onChangeFilter) {
        onChangeFilter(filter);
      }
    };

    return (
      <>
        <ReportPeriodSelector onChangeFilter={onChangeFilterHandler} />

        {report && (
          <div className={cx('container')}>
            {/*occupancy report*/}
            <div className={cx('row')}>
              <div className={cx('column')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('occupancyVsTraffic.popupTab.rate')}
                  value={`${numberUtils.toUiString(report.OccupancyPercentage ?? 0)}%`}
                  prevValue={`${numberUtils.toUiString(report.OccupancyPrevPercentage ?? 0)}%`}
                  change={report.OccupancyDiffPercentage ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
              <div className={cx('column', 'second-column')}>
                <OccupancyTrafficChart
                  category={localization.toLanguageStringF('occupancyVsTraffic.popupTab.hours')}
                  categories={report.OccupancyHourChartData.map((x) => localization.toLanguageStringF(`common.hours.${x.Hour}`))}
                  occupancySeries={report.OccupancyHourChartData}
                  trafficSeries={report.TrafficHourChartData}
                  trafficMaxValue={report.MaxSpeed}
                />
              </div>
            </div>

            {/*traffic report*/}
            <div className={cx('row')}>
              <div className={cx('column')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('occupancyVsTraffic.popupTab.avTrafficSpeed')}
                  value={localization.toLanguageStringF('occupancyVsTraffic.popupTab.trafficSpeedUnit', [
                    numberUtils.toUiString(report.CurrAvgSpeed ?? 0),
                  ])}
                  prevValue={localization.toLanguageStringF('occupancyVsTraffic.popupTab.trafficSpeedUnit', [
                    numberUtils.toUiString(report.PervAvgSpeed ?? 0),
                  ])}
                  change={report.DiffPercentageAvgSpeed ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
              <div className={cx('column', 'second-column')}>
                <OccupancyTrafficChart
                  category={localization.toLanguageStringF('occupancyVsTraffic.popupTab.days')}
                  categories={report.OccupancyDayChartData.map((x) => localization.toLanguageStringF(`common.weekDaysNum.${x.Day}`))}
                  occupancySeries={report.OccupancyDayChartData}
                  trafficSeries={report.TrafficDayChartData}
                  trafficMaxValue={report.MaxSpeed}
                />
              </div>
            </div>

            <div className={cx('row')}>
              <div className={cx('column', 'metrics')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('occupancyVsTraffic.popupTab.avTurnoverRate')}
                  value={`${numberUtils.toUiString(report.Turnover ?? 0)}`}
                  prevValue={`${numberUtils.toUiString(report.PrevTurnover ?? 0)}`}
                  change={report.DiffTurnover ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
              <div className={cx('column', 'metrics')}>
                <PeriodTextInfo
                  label={localization.toLanguageStringF('occupancyVsTraffic.popupTab.avDwellTime')}
                  value={dateUtils.formatMinutes(report.DwellTime ?? 0)}
                  prevValue={dateUtils.formatMinutes(report.PrevDwellTime ?? 0)}
                  change={report.DiffDwellTime ?? 0}
                  small={true}
                ></PeriodTextInfo>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }, [report, localization, onChangeFilter]);

  return content;
};
