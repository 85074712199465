import { FunctionComponent } from 'react';
import classNames from 'classnames/bind';

import styles from './HtmlLink.module.scss';

const cx = classNames.bind(styles);

interface Props {
  className?: string;
  children: React.ReactNode;
  href?: string | undefined;
  target?: string | undefined;
}

export const HtmlLink: FunctionComponent<Props> = ({ className, children, href, target }) => {
  return (
    <a
      href={href}
      className={cx(
        'k-button k-button-sm k-button-link k-button-link-primary',
        'button',
        className,
      )}
      target={target}
    >
      {children}
    </a>
  );
};
