import { FC } from 'react';
import { IPolicy } from '../../../../model/api/policy';
import { dateUtils } from '../../../../utils';
import { PolicyRules } from './PolicyRules';
import styles from './PolicyTile.module.scss';
import { PolicyTimeSpans } from './PolicyTimeSpans';

export const PolicyTile: FC<{ policy: IPolicy }> = ({ policy }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <PolicyTimeSpans timeSpans={policy.TimeSpans} className={styles.headerLabel}></PolicyTimeSpans>
        <div className={styles.headerText}>Published: {dateUtils.toLocalUiString(policy.PublishedDate, true, false)}</div>
      </div>
      <div className={styles.body}>
        <PolicyRules rules={policy.Rules} labelClass={styles.bodyLabel} textClass={styles.bodyText}></PolicyRules>
      </div>
    </div>
  );
};
