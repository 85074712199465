/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FC, useState } from 'react';
import { PopupEvent } from 'react-map-gl';
import classNames from 'classnames/bind';

import {
  currentOffstreetZone,
  selectAreaTypeNames,
  selectedAreaActions,
  selectedOffstreetZones,
  selectedOffstreetZonesActions,
  selectSidebarState,
  sidebarActions,
} from '../../../../features';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import { TabStripTab } from '@progress/kendo-react-layout';
import { useOpenPopupDispatch, useReportBuilder } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

import { ISelectedOffstreetZone, SidebarName } from '../../../../model';
import {
  ContentPlaceholder,
  Link,
  PinButton,
  PopupBodyPlaceholder,
  PopupHeaderPlaceholder,
  SelfOrientedPopup,
  Spinner,
  TabStrip,
} from '../../../common';
import { RevenuePeriodSelector, ViewReportLink } from '../../common';
import { OffstreetZoneIcon, OffstreetZoneVendorIcon } from '../../icons';

import { Position } from 'geojson';
import { amplitudeService, ISelectedEntity, PopupTab, PopupType, ReportName } from '../../../../services';
import styles from './OffstreetZonePopup.module.scss';

const cx = classNames.bind(styles);

const REVENUE_TAB = 1;

export const PopupItem: FC<{ zoneData: ISelectedEntity<ISelectedOffstreetZone> }> = ({ zoneData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const selectedSidebar = useAppSelector(selectSidebarState);
  const areaTypes = useAppSelector(selectAreaTypeNames);
  const currentZoneData = useAppSelector(currentOffstreetZone);
  const reportsBuilder = useReportBuilder();

  const zone = zoneData.entity;
  const areaType = zone?.area ? areaTypes[zone?.area?.TypeId] : null;
  const localization = useExtendedLocalization();
  const [selectedTab, setSelectedTab] = useState(0);

  const closePopup = (event: PopupEvent) => {
    dispatch(selectedOffstreetZonesActions.closePopup(zoneData.id));
  };

  const pinPopup = () => {
    dispatch(selectedOffstreetZonesActions.pinPopup(zoneData.id));
  };

  if (!zoneData.openPopup || !zoneData.position) return null;

  const spotsCount = zone?.SpotsCount ?? 0;

  const openArea = (areaId: number | undefined) => {
    areaId && popupDispatch(selectedAreaActions.loadArea({ id: areaId, position: null }));
  };

  const moreInfo = () => {
    dispatch(selectedOffstreetZonesActions.setCurrent(zone));
    dispatch(sidebarActions.setSelected({ name: SidebarName.offstreetZone, data: zone?.Id }));
  };

  const onDragEnd = (position: Position) => {
    if (!zoneData) {
      return;
    }
    dispatch(selectedOffstreetZonesActions.change({ id: zoneData.id, position: position }));
    amplitudeService.trackPopupMove(PopupType.OffstreetParking);
  };

  const onTabSelect = (tab: number) => {
    if (selectedTab === tab) return;

    setSelectedTab(tab);

    const popupTab = tab === REVENUE_TAB ? PopupTab.OffStreetRevenue : PopupTab.OffStreetGeneral;
    amplitudeService.trackPopupTab(popupTab);
  };

  const generalInfoTabContent = (
    <div className={cx('columns', 'tabContent')}>
      <div>
        {spotsCount > 0 ? (
          <div className='map-popup-row'>
            <label>{localization.toLanguageStringF('offstreetZone.popup.spaceCount')}:</label>
            <div className='row-value'>{zone?.SpotsCount}</div>
          </div>
        ) : null}

        <div className='map-popup-row'>
          <label>{localization.toLanguageStringF('common.vendor')}:</label>
          <div className='row-value'>{zone?.vendor?.Name}</div>
        </div>

        <div className='map-popup-row'>
          <label>{localization.toLanguageStringF('common.address')}:</label>
          <div className='row-value'>{zone?.Address}</div>
        </div>

        {zone?.area ? (
          <div className='map-popup-row'>
            <label>{areaType?.name}:</label>
            <div className='row-value'>
              <Link onClick={() => openArea(zone.AreaId)} className={styles.listItem}>
                {zone.area.Name}
              </Link>
            </div>
          </div>
        ) : null}
      </div>

      <OffstreetZoneVendorIcon className={styles.vendorIcon} vendorId={zone?.VendorId} />
    </div>
  );

  const content = (
    <>
      <ContentPlaceholder loading={zoneData.loading} element={<PopupHeaderPlaceholder />}>
        <div className={`map-popup-header ${styles.header}`}>
          <OffstreetZoneIcon className={styles.zoneIcon} />
          <h4> {zone?.Name} </h4>
          <div className={styles.headerDescription}>{localization.toLanguageStringF('offstreetZone.popup.title')}</div>
          <PinButton className={styles.pinButton} pinned={zoneData.pinnedPopup} onClick={pinPopup} />
        </div>
      </ContentPlaceholder>

      <div className='map-popup-body'>
        <TabStrip onSelectTab={onTabSelect}>
          <TabStripTab title={localization.toLanguageStringF('common.generalInfo')}>
            <ContentPlaceholder loading={zoneData.loading} element={<PopupBodyPlaceholder />}>
              {generalInfoTabContent}
            </ContentPlaceholder>
          </TabStripTab>
          <TabStripTab title={localization.toLanguageStringF('offstreetZone.revenue')}>
            <RevenuePeriodSelector offstreetZoneId={zoneData.id} report={zone?.revenueReport} />
          </TabStripTab>
        </TabStrip>

        <div className='map-popup-row more-info'>
          {selectedTab === REVENUE_TAB && (
            <ViewReportLink
              reportUrl={zone && reportsBuilder.getGarageRevenue(zone.Id)}
              reportName={ReportName.OffStreetRevenue}
              className={styles.reportLink}
            />
          )}
          <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.offstreetZone && currentZoneData?.id === zone?.Id}>
            {localization.toLanguageStringF('common.moreInfo')}
          </Link>
        </div>
      </div>
    </>
  );

  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={zone}
        longitude={zoneData.position[0]}
        latitude={zoneData.position[1]}
        onClose={closePopup}
        closeOnClick={false}
        className='offstreet-zone-popup'
        maxWidth='690px'
        updateOn={selectedTab}
        initPosition={zoneData.initPosition}
        onDragEnd={onDragEnd}
      >
        <div className={`${styles.popup}`}>
          {content}
          {zoneData.loading && <Spinner backdrop={true} />}
        </div>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};

export const OffstreetZonePopup: FC = () => {
  const zonesData = useAppSelector(selectedOffstreetZones);
  const zones = zonesData.selected;

  return (
    <>
      {zones.map((x) => (
        <PopupItem key={x.id} zoneData={x} />
      ))}
    </>
  );
};
