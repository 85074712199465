import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-react-layout';
import { Button, DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import { FunctionComponent, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectUserProfile } from '../../../features/common/auth/auth-slice';
import Search from './search/Search';
import { metersEditingActions, selectAlertsState, selectCities } from '../../../features';
import { useExtendedLocalization } from '../../../hooks/use-extended-localization-service';

import { logout } from '../../../features';
import { AlertList } from './alert-list/AlertList';
import { Environment, useGetEnvironment, useLoadingZonesEnabled, useReportBuilder } from '../../../hooks';
import { DemoMenu, NavigationUrl } from './demo-menu/DemoMenu';

import styles from './header.module.scss';
import logo from './logo.svg';
import logoDemoMode from './logo-demo-mode.svg';
import logoNexcityDemoMode from './logo-nexcity-demo-mode.svg';
import logoNexparkDemoMode from './logo-nexpark-demo-mode.svg';

const demoModeNexCityUrls: NavigationUrl[] = [
  { text: 'Portal', url: '/map' },
  { text: 'Reports', url: '/reports' },
  { text: 'Loading Zones', url: '/loading-zones' },
  { text: 'Forecast', url: '/forecast' },
];

const demoModeNexParkUrls: NavigationUrl[] = [
  { text: 'Portal', url: 'https://nexpark.umojodev.com' },
  { text: 'Reports', url: 'https://demo.nexcity.dev/#/reports/command-center/dashboard' },
  { text: 'Voice Platform UI', url: 'https://www.umojo.com' },
  { text: 'PARC Analytics', url: 'https://www.umojo.com' },
  {
    text: 'Overhead Occupancy',
    url: 'https://app.powerbi.com/groups/2779977a-6f05-44ab-86f9-2ecd77fa2ec4/reports/e453056c-debb-44cf-ae77-6fc63521e689/ReportSection4b34ec9406260035c60d?experience=power-bi',
  },
  { text: 'LPR', url: 'https://www.umojo.com' },
];

const Header: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const userProfile = useAppSelector(selectUserProfile);
  const localization = useExtendedLocalization();
  const cities = useAppSelector(selectCities);
  const loadingZonesEnabled = useLoadingZonesEnabled();
  const [showAlerts, setShowAlerts] = useState(false);
  const alerts = useAppSelector(selectAlertsState).alerts;
  const environment = useGetEnvironment();
  const [demoMode, setDemoMode] = useState(false);
  const alertsCount = alerts.length + 5;

  const isEditVisible = userProfile?.isAdmin && location.pathname.toLowerCase() === '/map';

  const reportsBuilder = useReportBuilder();

  const onItemClick = (text: string) => {
    switch (text) {
      case localization.toLanguageStringF('header.menu.logout'): {
        logout();
        break;
      }
      case localization.toLanguageStringF('header.menu.editMode'): {
        dispatch(metersEditingActions.setEditing({ edit: true, canEdit: userProfile?.isAdmin || false }));
        break;
      }
      case localization.toLanguageStringF('header.menu.demoMode'):
      case localization.toLanguageStringF('header.menu.exitDemoMode'): {
        setDemoMode((x) => !x);
        break;
      }
      default: {
        break;
      }
    }
  };

  if (!isAuthenticated) return null;

  const theme = 'dark';

  return (
    <header className={styles.header}>
      <AppBar className={styles.appBar} themeColor={theme}>
        <AppBarSection>
          <NavLink to='/'>
            <img className={styles.logo} src={demoMode ? logoDemoMode : logo} alt='logo' />
          </NavLink>
        </AppBarSection>

        {cities.cities.length > 0 ? (
          demoMode ? (
            <>
              <AppBarSection>
                <DemoMenu title='NexCity' icon={logoNexcityDemoMode} urls={demoModeNexCityUrls} className={styles.demoMenuMargin} />
              </AppBarSection>
              <AppBarSection>
                <DemoMenu title='NexPark' icon={logoNexparkDemoMode} urls={demoModeNexParkUrls} />
              </AppBarSection>
            </>
          ) : (
            <AppBarSection>
              <ul>
                <li>
                  <NavLink className={styles.link} to='/map'>
                    {localization.toLanguageStringF('header.menu.map')}
                  </NavLink>
                </li>
                {reportsBuilder.hasReports() && (
                  <li>
                    <NavLink className={styles.link} to='/reports'>
                      {localization.toLanguageStringF('header.menu.reports')}
                    </NavLink>
                  </li>
                )}
                {loadingZonesEnabled && reportsBuilder.getLoadingZonesDashboard() && (
                  <li>
                    <NavLink className={styles.link} to='/loading-zones'>
                      {localization.toLanguageStringF('header.menu.loadingZones')}
                    </NavLink>
                  </li>
                )}
                {reportsBuilder.getForecasting() && (
                  <li>
                    <NavLink className={styles.link} to='/forecast'>
                      {localization.toLanguageStringF('header.menu.forecasting')}
                    </NavLink>
                  </li>
                )}
              </ul>
            </AppBarSection>
          )
        ) : null}

        <AppBarSpacer />

        {cities.cities.length > 0 ? (
          <>
            <AppBarSection>
              <Search />
            </AppBarSection>
            {environment !== Environment.Prod && (
              <AppBarSection>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                <div role='button' tabIndex={0} className={styles.alertContainer} onClick={() => setShowAlerts((x) => !x)}>
                  <Button icon='notification' className={styles.alertIcon} />
                  {alertsCount > 0 ? <span className={styles.badge}>{alertsCount}</span> : null}
                </div>
              </AppBarSection>
            )}
          </>
        ) : null}

        <AppBarSection>
          <DropDownButton
            icon='arrow-60-down'
            buttonClass={styles.dropdownIcon}
            themeColor='light'
            fillMode='link'
            rounded='small'
            text={userProfile?.name}
            popupSettings={{ popupClass: styles.dropdownPopup }}
            onItemClick={(evt) => onItemClick(evt.item.text)}
          >
            {isEditVisible && <DropDownButtonItem text={localization.toLanguageStringF('header.menu.editMode')} icon='edit' />}
            {cities.cities.length > 0 && environment === Environment.Demo && (
              <DropDownButtonItem
                text={
                  demoMode
                    ? localization.toLanguageStringF('header.menu.exitDemoMode')
                    : localization.toLanguageStringF('header.menu.demoMode')
                }
                iconClass={demoMode ? styles.demoModeExitIcon : 'k-i-toggle-full-screen-mode'}
              />
            )}
            <DropDownButtonItem text={localization.toLanguageStringF('header.menu.logout')} icon='logout' />
          </DropDownButton>
        </AppBarSection>
      </AppBar>

      {environment !== Environment.Prod && (
        <Popup show={showAlerts} offset={{ top: 61, left: window.innerWidth - 530 }}>
          <AlertList onClose={() => setShowAlerts(false)} />
        </Popup>
      )}
    </header>
  );
};

export default Header;
