/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from '@progress/kendo-react-buttons';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import React, { FC, useCallback } from 'react';

import classNames from 'classnames/bind';
import uniqBy from 'lodash/uniqBy';

import {
  currentCurbSpace,
  selectedCurbSpacesActions,
  selectedMetersActions,
  selectedStudyAreasActions,
  selectedZonesActions,
  sidebarActions,
} from '../../../../features';
import { useOccupancyTrafficEnabled, useOpenPopupDispatch } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { DayOfWeekId, IScheduleItem, ISelectedCurbSpace, ISpotRegulation, SidebarName, WeekDaysSchedule } from '../../../../model';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { dateUtils, geoUtils } from '../../../../utils';
import { Link } from '../../../common';
import { ParkingEventsList } from '../../common/parking-events/ParkingEventsList';
import { MapSidebarContainer } from '../../controls';
import { CameraThumbnail } from '../cameras/camera-thumbnail/CameraThumbnail';
import { SignThumbnail } from '../signs/sign-thumbnail/SignThumbnail';
import { CurbSpaceOccupancyStatusText } from './CurbSpaceOccupancyStatusText';

import { ISelectedEntity, NavigationSource, PopupType, amplitudeService } from '../../../../services';
import styles from './CurbSpaceSidebar.module.scss';
import { OccupancyTable, SidebarSectionLazyLoader } from '../../common';

const cx = classNames.bind(styles);

const SidebarRow: FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => (
  <div className='map-sidebar-row'>
    <label>{title}</label>
    <div>{children}</div>
  </div>
);

const Regulation: FC<{ regulation: ISpotRegulation | null }> = ({ regulation }) => {
  const localization = useExtendedLocalization();

  if (!regulation?.Schedules || regulation.Schedules.length === 0) return null;

  const uniqRegulations = regulation.Schedules.map((schedule, indexSh) => {
    return uniqBy(
      schedule.Items.map((item) => ({
        Time: item.FromTime + '&' + item.ToTime,
        FromTime: item.FromTime,
        ToTime: item.ToTime,
        Rates: item.Rates,
        DayOfWeekId: item.DayOfWeekId,
        TimeLimitMinutes: item.TimeLimitMinutes,
        ScheduleNumber: indexSh,
        ScheduleTypeId: schedule.ScheduleTypeId,
      })),
      (x) => x.Time,
    );
  });

  const sumDays = (scheduleNumber: number, currentTime: string) => {
    if (regulation.Schedules[scheduleNumber]) {
      const weekDays: (DayOfWeekId | undefined)[] = regulation.Schedules[scheduleNumber].Items.map((value: IScheduleItem) => {
        if (currentTime === value.FromTime + '&' + value.ToTime) {
          return value.DayOfWeekId;
        }
        return undefined;
      });

      const uniqWeekDays: DayOfWeekId[] = weekDays.filter((x) => x !== undefined).map((x) => x as DayOfWeekId);

      let weekDayString = '';

      if (uniqWeekDays.length > 1) {
        weekDayString =
          localization.toLanguageStringF(
            `curbSpace.dayOfWeek.${
              DayOfWeekId[Object.values(DayOfWeekId)[Math.min(...uniqWeekDays.map((x) => Object.keys(DayOfWeekId).indexOf(x)))]]
            }`,
          ) +
          ' - ' +
          localization.toLanguageStringF(
            `curbSpace.dayOfWeek.${
              DayOfWeekId[Object.values(DayOfWeekId)[Math.max(...uniqWeekDays.map((x) => Object.keys(DayOfWeekId).indexOf(x)))]]
            }`,
          );
      }

      if (uniqWeekDays.length === 1) {
        weekDayString = localization.toLanguageStringF(`curbSpace.dayOfWeek.${DayOfWeekId[uniqWeekDays[0]]}`);
      }

      return weekDayString;
    }
    return '';
  };

  return (
    <>
      {uniqRegulations.map((schedule, shIndex) => (
        <div key={shIndex} className={styles.schedule}>
          {schedule.map((item, itemIndex) => (
            <div key={itemIndex} className='map-sidebar-row'>
              <div className={styles.leftColumn}>
                <div>{`${WeekDaysSchedule.getAmericanHour(item.FromTime)} - ${WeekDaysSchedule.getAmericanHour(item.ToTime)}`}</div>
                <div>{sumDays(shIndex, item.Time)}</div>
              </div>

              <div className={styles.rightColumn}>
                {item.Rates.map((rate, rateIndex) =>
                  rate.Rate !== 0 ? (
                    <div key={rateIndex} className={styles.scheduleRate}>
                      {rate.Rate + localization.toLanguageStringF('curbSpace.sidebar.regulations.rate')}
                      <span className={styles.regulationAlt}>{WeekDaysSchedule.diffHourDescription(rate?.FromTime, rate?.ToTime)}</span>
                    </div>
                  ) : (
                    <div key={rateIndex} className={cx('rightColumn', { padding: !item.TimeLimitMinutes })}>
                      {localization.toLanguageStringF(`curbSpace.scheduleType.${item.ScheduleTypeId}`)}
                    </div>
                  ),
                )}
                {item.TimeLimitMinutes && (
                  <div className={styles.regulationAlt}>
                    {localization.toLanguageStringF('curbSpace.sidebar.regulations.parkingLimit', [item.TimeLimitMinutes])}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

interface Props {
  curbSpaceData: ISelectedEntity<ISelectedCurbSpace>;
}

const Sidebar: FC<Props> = ({ curbSpaceData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const curbSpace = curbSpaceData.entity;
  const localization = useExtendedLocalization();
  const occupancyTrafficEnabled = useOccupancyTrafficEnabled();

  const handleCloseClick = (ev: React.FormEvent) => {
    ev.preventDefault();
    dispatch(sidebarActions.clearSelected());
  };

  const mapViewClick = () => {
    dispatch(selectedCurbSpacesActions.openPopup(curbSpaceData.id));
  };

  const openZone = useCallback(
    (zoneId: number | undefined) => {
      if (!zoneId) return;

      popupDispatch(selectedZonesActions.loadZone({ id: zoneId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Zones, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openMeter = useCallback(
    (meterId: number) => {
      popupDispatch(selectedMetersActions.loadMeter({ id: meterId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Meters, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  const openStudyArea = useCallback(
    (studyAreaId: number) => {
      popupDispatch(selectedStudyAreasActions.loadStudyArea({ id: studyAreaId, position: null }));
      amplitudeService.trackPopupOpen(PopupType.Study, NavigationSource.Navigation);
    },
    [popupDispatch],
  );

  if (!curbSpace) {
    return null;
  }

  const cameraEvent = curbSpace.cameraEvents.length ? curbSpace.cameraEvents[0] : null;

  return (
    <div className='map-sidebar map-sidebar map-sidebar-info'>
      <div className='map-sidebar-header'>
        <h3>{localization.toLanguageStringF('curbSpace.sidebar.title', [curbSpace.Name])}</h3>
        <Button size='small' className='close' icon='close' onClick={handleCloseClick}></Button>
      </div>

      <div className='map-sidebar-body'>
        <PanelBar keepItemsMounted={true}>
          <PanelBarItem title={localization.toLanguageStringF('common.generalInfo')} expanded={true}>
            <div>
              <SidebarRow title={localization.toLanguageStringF('common.status') + ':'}>
                {curbSpace.state && <CurbSpaceOccupancyStatusText status={curbSpace.state.Status} />}
              </SidebarRow>
              {curbSpace.studyAreas.length > 0 && (
                <SidebarRow title={`${localization.toLanguageStringF('curbSpace.studyAreas')}:`}>
                  {curbSpace.studyAreas.map((x) => (
                    <Link key={x.Id} className={styles.meterButton} onClick={() => openStudyArea(x.Id)}>
                      {x.Name}
                    </Link>
                  ))}
                </SidebarRow>
              )}
              <SidebarRow title={localization.toLanguageStringF('common.zone') + ':'}>
                <Link onClick={() => openZone(curbSpace?.zone?.Id)} className={styles.link}>
                  {curbSpace?.zone?.Name}
                </Link>
              </SidebarRow>
              <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.meters') + ':'}>
                {curbSpace.metersNames.map((x) => (
                  <Link key={x.Id} className={styles.meterButton} onClick={() => openMeter(x.Id)}>
                    {x.Name}
                  </Link>
                ))}
              </SidebarRow>
              <SidebarRow title={localization.toLanguageStringF('common.geo') + ':'}>{geoUtils.toUiString(curbSpace.Position)}</SidebarRow>
              {curbSpace.state?.CompanyName && (
                <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.carrier') + ':'}>
                  {curbSpace.state.CompanyName}
                </SidebarRow>
              )}
            </div>
            <div className='map-sidebar-row more-info'>
              <Link onClick={mapViewClick} className='more-info-button' disabled={curbSpaceData.openPopup}>
                {localization.toLanguageStringF('common.mapView')}
              </Link>
            </div>
          </PanelBarItem>

          <PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.events')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={(curbSpace.events?.length || 0) > 0}
              shouldLoadData={curbSpace.events === undefined}
              loadData={() => dispatch(selectedCurbSpacesActions.fetchEvents(curbSpace.Id))}
            >
              <ParkingEventsList className={styles.panelMargin} events={curbSpace.events || []} openPopupOnSelect={true} />
            </SidebarSectionLazyLoader>
          </PanelBarItem>

          {/*<PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.regulations.title')} expanded={false}>
            <SidebarSectionLazyLoader
              hasData={!!curbSpace.regulation?.Schedules && curbSpace.regulation.Schedules.length > 0}
              shouldLoadData={curbSpace.regulation === undefined}
              loadData={() => dispatch(selectedCurbSpacesActions.fetchRegulation(curbSpace.Id))}
            >
              {curbSpace.PolicyTypeId !== 0 && (
                <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.regulations.policy') + ':'}>
                  <span className={`${styles.policyLabel} ${styles['policy' + curbSpace.PolicyTypeId]}`}>
                    {localization.toLanguageStringF(`curbSpace.policyType.${curbSpace.PolicyTypeId}`)}
                  </span>
                </SidebarRow>
              )}
              <div className='map-sidebar-row'>
                <label>{localization.toLanguageStringF('curbSpace.sidebar.ratesSchedule')}:</label>
              </div>
              {curbSpace.regulation && <Regulation regulation={curbSpace.regulation} />}
            </SidebarSectionLazyLoader>
            </PanelBarItem>*/}

          {curbSpace.cameraState && (
            <PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.camera.title')} expanded={false}>
              <div className='map-sidebar-row'>
                <CameraThumbnail state={curbSpace.cameraState} thumbnailWidth={110} thumbnailHeight={70} />
                {cameraEvent && (
                  <div className={styles.rightColumn}>
                    <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.camera.timeIn')}>
                      {dateUtils.toLocalUiString(cameraEvent.Start, false, true)}
                    </SidebarRow>
                    <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.camera.timeOut')}>
                      {dateUtils.toLocalUiString(cameraEvent.End, false, true)}
                    </SidebarRow>
                    <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.camera.duration')}>
                      {dateUtils.diffMinutes(cameraEvent.Start, cameraEvent.End)}
                    </SidebarRow>
                    <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.camera.type')}>
                      {cameraEvent.VehicleType}
                    </SidebarRow>
                    <SidebarRow title={localization.toLanguageStringF('curbSpace.sidebar.camera.event')}>
                      {localization.toLanguageStringF(`parkingEvent.lane.${cameraEvent.EventType}.l`) || cameraEvent.EventType}
                    </SidebarRow>
                  </div>
                )}
              </div>
            </PanelBarItem>
          )}

          {curbSpace.signState && (
            <PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.sign.title')} expanded={false} keepItemsMounted={true}>
              <SignThumbnail state={curbSpace.signState} thumbnailWidth={106} className={styles.panelMargin} />
            </PanelBarItem>
          )}

          {occupancyTrafficEnabled && (
            <PanelBarItem title={localization.toLanguageStringF('curbSpace.sidebar.occupancyReport.title')} expanded={false}>
              <SidebarSectionLazyLoader
                hasData={(curbSpace.occupancyReport?.length || 0) > 0}
                shouldLoadData={curbSpace.occupancyReport === undefined}
                loadData={() => dispatch(selectedCurbSpacesActions.fetchOccupancyReport(curbSpace.Id))}
              >
                <OccupancyTable occupancyReport={curbSpace.occupancyReport || []} />
              </SidebarSectionLazyLoader>
            </PanelBarItem>
          )}
        </PanelBar>
      </div>
    </div>
  );
};

export const CurbSpaceSidebar: FC = () => {
  const current = useAppSelector(currentCurbSpace);

  return current?.entity ? (
    <MapSidebarContainer name={SidebarName.curbSpace}>
      <Sidebar curbSpaceData={current} />
    </MapSidebarContainer>
  ) : null;
};
