import React, { FunctionComponent, useCallback } from 'react';
import { PopupEvent } from 'react-map-gl';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  selectAreaTypes,
  selectedArea,
  selectedAreaActions,
  selectedOffstreetZonesActions,
  selectedZonesActions,
  selectSidebarState,
  sidebarActions,
} from '../../../../features';
import { areaUtility } from './area-helpers';

import styles from './AreaPopup.module.scss';
import { Collapsible, Spinner, Link, SelfOrientedPopup } from '../../../common';
import { AreaIcon } from '../../icons';
import { SidebarName } from '../../../../model';
import { useOpenPopupDispatch } from '../../../../hooks';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

export const AreaPopup: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const selectedSidebar = useAppSelector(selectSidebarState);
  const areaTypes = useAppSelector(selectAreaTypes);
  const areaData = useAppSelector(selectedArea);
  const area = areaData.area;
  const localization = useExtendedLocalization();

  const closePopup = (event: PopupEvent) => {
    dispatch(selectedAreaActions.closePopup());
  };

  const subDistrictClick = useCallback(
    (areaId: number, typeId: number) => {
      popupDispatch(
        selectedAreaActions.loadArea({
          id: areaId,
          position: null,
        }),
      );
    },
    [popupDispatch],
  );

  const openZone = useCallback(
    (zoneId: number) => {
      popupDispatch(
        selectedZonesActions.loadZone({
          id: zoneId,
          position: null,
        }),
      );
    },
    [popupDispatch],
  );

  const openOffstreetZone = useCallback(
    (zoneId: number) => {
      popupDispatch(
        selectedOffstreetZonesActions.loadZone({
          id: zoneId,
          position: null,
        }),
      );
    },
    [popupDispatch],
  );

  const moreInfo = () => {
    dispatch(sidebarActions.setSelected({ name: SidebarName.area }));
  };

  const { areaCounts, isNotEmptyChildren, showSidebar } = areaUtility(area, areaTypes);
  const zoneNames = area?.zoneNames || [];
  const offstreetZoneNames = area?.offstreetZoneNames || [];

  if (!areaData.openPopup || !areaData.position) return null;

  const content = (
    <>
      <div className={`map-popup-header ${styles.header}`}>
        <AreaIcon className='area-icon' />
        <h4>
          {area?.typeName}: {area?.Name}
        </h4>
        {isNotEmptyChildren && (
          <div className='header-description'>
            {areaCounts.map((area, idx) => (
              <span key={idx}>
                {areaTypes && areaTypes[area.typeId] ? areaTypes[area.typeId].PluralName : area.name} {idx < areaTypes.length - 1 && ' |'}
                {': ' + area.descendants.length}
              </span>
            ))}
          </div>
        )}
      </div>

      <div className='map-popup-body'>
        {isNotEmptyChildren &&
          areaCounts.map((area, index) => (
            <div key={index} className='map-popup-row'>
              <Collapsible header={areaTypes && areaTypes[area.typeId] ? areaTypes[area.typeId].PluralName : ''} expanded={false}>
                {area.descendants.map((child, idx) => (
                  <span key={idx} className='map-sidebar-row descendant-link'>
                    <Link onClick={() => subDistrictClick(child.Id, child.TypeId)} className={styles.listItem}>
                      {child?.Name}
                    </Link>
                  </span>
                ))}
              </Collapsible>
            </div>
          ))}

        {zoneNames.length > 0 && (
          <div className='map-popup-row'>
            <div className={`${styles.columnLabel} ${(isNotEmptyChildren && styles.addleftMargin) || ''}`}>
              <label htmlFor='fff'>{localization.toLanguageStringF('area.popup.zones')}:</label>
              <div className={styles.labelDescription}>
                {zoneNames.length} {zoneNames.length === 1 ? 'item' : 'items'}
              </div>
            </div>
            <div className='row-value' style={{ width: '100%' }}>
              {zoneNames.slice(0, 5).map((zone, idx) => (
                <Link key={idx} onClick={() => openZone(zone.Id)} className={styles.listItem}>
                  <span>{zone.Name}</span>
                  {idx < Math.min(zoneNames.length, 5) - 1 && ','}
                </Link>
              ))}
              {zoneNames.length > 5 && <label htmlFor='fff'>...</label>}
            </div>
          </div>
        )}

        {offstreetZoneNames.length > 0 && (
          <div className='map-popup-row'>
            <div className={`column-label ${isNotEmptyChildren} ? 'add-left-margin' : ''`}>
              <label htmlFor='fff'>{localization.toLanguageStringF('area.popup.offStreetParking')}:</label>
              <div className='label-description'>
                {offstreetZoneNames.length} {offstreetZoneNames.length === 1 ? 'item' : 'items'}
              </div>
            </div>
            <div className='row-value' style={{ width: '100%' }}>
              {offstreetZoneNames.slice(0, 5).map((zone, idx) => (
                <Link key={idx} onClick={() => openOffstreetZone(zone.Id)} className={styles.listItem}>
                  <span>{zone.Name}</span>
                  {idx < Math.min(offstreetZoneNames.length, 5) - 1 && ','}
                </Link>
              ))}
              {offstreetZoneNames.length > 5 && <label htmlFor='fff'>...</label>}
            </div>
          </div>
        )}

        {showSidebar ? (
          <div className='map-popup-row more-info'>
            <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.area}>
              {localization.toLanguageStringF('common.moreInfo')}
            </Link>
          </div>
        ) : (
          <div className='map-popup-row no-data'>{localization.toLanguageStringF('area.popup.noSubareaAvailable')}</div>
        )}
      </div>
    </>
  );

  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={area}
        longitude={areaData.position[0]}
        latitude={areaData.position[1]}
        onClose={closePopup}
        className='area-popup'
        maxWidth='496px'
      >
        <div className={`${styles.popup}`}>
          {content}

          {areaData.loading && <Spinner backdrop={true} />}
        </div>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};
