import { Action } from '@reduxjs/toolkit';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { StateObservable, combineEpics } from 'redux-observable';
import { Observable, from, of, zip } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { ISelectedBlockface } from '../../../../model';
import { blockfaces, geoProcessor, meters, reports, revenueData, sensors, spots, studyAreas } from '../../../../services';
import { RootState } from '../../../../store';
import { geoUtils } from '../../../../utils';
import { citiesActions } from '../../../common';
import { mapStateActions } from '../../map-state';
import { blockfacesGeoActions } from './blockfaces-geo-slice';
import { blockfacesLayerActions } from './blockfaces-layer-slice';
import { selectedBlockfacesActions } from './selected-blockfaces-slice';

const loadBlockface = (id: number, state: RootState): Observable<ISelectedBlockface> => {
  const existing = state.selectedBlockfaces.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return zip(
    from(blockfaces.get(id)),
    from(meters.getBlockfaceMeterNames(id)),
    from(sensors.getBlockfaceSensorSpots(id)),
    from(spots.getBlockfaceSpotsStates(id)),
    from(studyAreas.getBlockfaceStudyAreaNames(id)),
  ).pipe(
    map(([blockface, meters, sensors, spotsStates, studyAreas]) => ({
      ...blockface,
      metersNames: meters,
      sensors: sensors,
      spotsStates: spotsStates,
      studyAreas: studyAreas,
    })),
  );
};

const findBlockfaceFeature = (id: number, state: RootState): Feature<Geometry, GeoJsonProperties> | null => {
  const result = state.blockfacesGeo.data.features.find((x) => x.properties?.id === id);
  return result || null;
};

const fetchCountEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(blockfacesLayerActions.fetchCount.match),
    concatMap(() =>
      from(blockfaces.getCount()).pipe(
        mergeMap((x) => {
          if (x === 0) {
            return of(blockfacesLayerActions.fetchCountSuccess(x));
          }
          return of(blockfacesLayerActions.fetchCountSuccess(x));
        }),
        catchError((err) => of(blockfacesLayerActions.fetchCountFailed(err.message))),
      ),
    ),
  );

const citySelectedEpic = (actions$: Observable<Action>): Observable<Action> =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap((_) => of(blockfacesLayerActions.fetchCount(), selectedBlockfacesActions.collapsePopups())),
  );

const fetchBlockfacesEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(blockfacesGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor.loadBlockfaces(action.payload, action.payload.zoom, state$.value.blockfacesLayer.showPerformanceParkingOnly).pipe(
        map((x) => blockfacesGeoActions.fetchSuccess(x)),
        catchError((err) => of(blockfacesGeoActions.fetchFailed(err.message))),
      ),
    ),
  );

const blockfaceSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedBlockfacesActions.loadBlockface.match),
    concatMap((action) =>
      loadBlockface(action.payload.id, state$.value).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              blockfacesLayerActions.setEnabled(true),
              selectedBlockfacesActions.loadBlockfaceSuccess({
                blockface: x,
                position: center,
                initPosition: initPosition ? initPosition : center,
              }),
            );
          } else {
            const feature: Feature<Geometry, GeoJsonProperties> = findBlockfaceFeature(action.payload.id, state$.value) || {
              type: 'Feature',
              geometry: geoUtils.toGeometry(x.Positions),
              properties: {},
            };

            const center = geoUtils.findCenter(feature).geometry.coordinates;
            return of(
              blockfacesLayerActions.setEnabled(true),
              mapStateActions.setMapCenter(center),
              selectedBlockfacesActions.loadBlockfaceSuccess({ blockface: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedBlockfacesActions.loadBlockfaceFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((_) => selectedBlockfacesActions.closePopups()),
  );

const fetchViolationReportEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfacesActions.fetchViolationReport.match),
    concatMap((action) => {
      return from(reports.getBlockfaceEnforcementReport(action.payload.blockfaceId, action.payload.filter)).pipe(
        map((x) => selectedBlockfacesActions.fetchViolationReportSuccess({ blockfaceId: action.payload.blockfaceId, report: x })),
        catchError((err) => of(selectedBlockfacesActions.fetchViolationReportFailed(err.message))),
      );
    }),
  );
};

const fetchRevenueReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfacesActions.fetchRevenueReport.match),
    concatMap((action) => {
      return from(reports.getBlockfaceRevenueReport(action.payload.blockfaceId, action.payload.filter)).pipe(
        map((x) => selectedBlockfacesActions.fetchRevenueReportSuccess({ blockfaceId: action.payload.blockfaceId, report: x })),
        catchError((err) => of(selectedBlockfacesActions.fetchRevenueReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyTrafficReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfacesActions.fetchOccupancyTrafficRepor.match),
    concatMap((action) => {
      return from(reports.getBlockfaceOccupancyTrafficReport(action.payload.blockfaceId, action.payload.filter)).pipe(
        map((x) => selectedBlockfacesActions.fetchOccupancyTrafficReportSuccess({ blockfaceId: action.payload.blockfaceId, report: x })),
        catchError((err) => of(selectedBlockfacesActions.fetchOccupancyTrafficReportFailed(err.message))),
      );
    }),
  );
};

const fetchPerformanceParkingSuccessEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.fetchPerformanceParkingCountSuccess.match),
    filter((x) => x.payload === 0),
    map((_) => blockfacesLayerActions.setPerformanceParkingFilterValue(false)),
  );

const fetchRevenueEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfacesActions.fetchRevenue.match),
    concatMap((action) => {
      return from(revenueData.getRevenueByBlockface(action.payload)).pipe(
        map((x) => selectedBlockfacesActions.fetchRevenueSuccess({ blockfaceId: action.payload, revenue: x })),
        catchError((err) => of(selectedBlockfacesActions.fetchRevenueFailed(err.message))),
      );
    }),
  );
};

const fetchKpiReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfacesActions.fetchKpiReport.match),
    concatMap((action) => {
      return from(reports.getBlockfaceKpiReport(action.payload)).pipe(
        map((x) => selectedBlockfacesActions.fetchKpiReportSuccess({ blockfaceId: action.payload, report: x })),
        catchError((err) => of(selectedBlockfacesActions.fetchKpiReportFailed(err.message))),
      );
    }),
  );
};

const fetchTrafficReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfacesActions.fetchTrafficReport.match),
    concatMap((action) => {
      return from(reports.getBlockfaceTrafficReport(action.payload)).pipe(
        map((x) => selectedBlockfacesActions.fetchTrafficReportSuccess({ blockfaceId: action.payload, report: x })),
        catchError((err) => of(selectedBlockfacesActions.fetchTrafficReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedBlockfacesActions.fetchOccupancyReport.match),
    concatMap((action) => {
      return from(reports.getBlockfaceOccupancyReport(action.payload)).pipe(
        map((x) => selectedBlockfacesActions.fetchOccupancyReportSuccess({ blockfaceId: action.payload, report: x })),
        catchError((err) => of(selectedBlockfacesActions.fetchOccupancyReportFailed(err.message))),
      );
    }),
  );
};

export const blockfacesEpic = combineEpics(
  fetchCountEpic,
  citySelectedEpic,
  fetchBlockfacesEpic,
  blockfaceSelectedEpic,
  closePopupsEpic,
  fetchViolationReportEpic,
  fetchRevenueReportEpic,
  fetchOccupancyTrafficReportEpic,
  fetchPerformanceParkingSuccessEpic,
  fetchRevenueEpic,
  fetchKpiReportEpic,
  fetchTrafficReportEpic,
  fetchOccupancyReportEpic,
);
