import React, { FC, FunctionComponent } from 'react';
import { PopupEvent } from 'react-map-gl';

import {
  ISelectedSign,
  selectedSigns,
  selectedZonesActions,
  selectSidebarState,
  sidebarActions,
  selectedSignsActions,
  currentSign,
} from '../../../../features';

import { useLoadingZonesEnabled, useOpenPopupDispatch } from '../../../../hooks';
import { SidebarName, ZoneType } from '../../../../model';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Link, PinButton, PopupPlaceholder, SelfOrientedPopup } from '../../../common';
import { LedColorText } from '../../../common/led-colot-text/LedColorText';
import { ServerImg } from '../../../common/server-img/ServerImg';
import { SignStatusIcon } from '../../icons';
import { CurbSpacesList } from '../curb-spaces/curb-spaces-list/CurbSpacesList';
import { SignStatusText } from './SignStatusText';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { dateUtils } from '../../../../utils';
import { LoadingZoneQR } from '../loading-zones/LoadingZoneQR';

import styles from './SignPopup.module.scss';
import classNames from 'classnames';
import { ISelectedEntity, NavigationSource, PopupType, amplitudeService } from '../../../../services';
import { Position } from 'geojson';

export const PopupItem: FC<{ selectedSignData: ISelectedEntity<ISelectedSign> }> = ({ selectedSignData }) => {
  const dispatch = useAppDispatch();
  const popupDispatch = useOpenPopupDispatch();
  const selectedSidebar = useAppSelector(selectSidebarState);
  const localization = useExtendedLocalization();
  const cx = classNames.bind(styles);
  const currentSignData = useAppSelector(currentSign);
  const loadingZonesEnabled = useLoadingZonesEnabled();

  const sign = selectedSignData.entity;

  const openZone = () => {
    if (sign?.zone) {
      popupDispatch(
        selectedZonesActions.loadZone({
          id: sign.zone.Id,
          position: null,
        }),
      );
      amplitudeService.trackPopupOpen(PopupType.Zones, NavigationSource.Navigation);
    }
  };

  const closePopup = (event: PopupEvent) => {
    dispatch(selectedSignsActions.closePopup(sign?.Id));
  };

  const pinPopup = () => {
    dispatch(selectedSignsActions.pinPopup(selectedSignData.id));
  };

  const moreInfo = () => {
    dispatch(selectedSignsActions.setCurrent(sign));
    dispatch(sidebarActions.setSelected({ name: SidebarName.sign, data: sign?.Id }));
  };

  const onDragEnd = (position: Position) => {
    if (!sign) {
      return;
    }
    dispatch(selectedSignsActions.change({ id: sign.Id, position: position }));
  };

  if (!selectedSignData.openPopup || !selectedSignData.position) return null;

  const content = (
    <>
      <div className={`map-popup-header ${styles.header}`}>
        {sign?.state && <SignStatusIcon className={`${styles.statusIcon}`} status={sign?.state?.Status} />}
        <h4>{localization.toLanguageStringF('sign.popup.title', [sign?.DeviceId])}</h4>
        {sign?.state && <SignStatusText className={`${styles.headerDescription}`} status={sign?.state?.Status} />}
        <PinButton className={styles.pinButton} pinned={selectedSignData.pinnedPopup} onClick={pinPopup} />
      </div>

      <div className='map-popup-body'>
        <div className={`${styles.signRows}`}>
          <div className={`${styles.leftColumn}`}>
            {loadingZonesEnabled && sign?.zone?.Type === ZoneType.Loading ? (
              <LoadingZoneQR zoneNumber={sign?.zone?.Code} height={200} />
            ) : (
              sign?.state?.ActiveScreen && (
                <ServerImg
                  url={`sign/screen/${sign.state.ActiveScreen.Id}/image`}
                  className={styles.activeScreen}
                  discriminator={sign.fetchedAt}
                />
              )
            )}
          </div>
          <div className={`${styles.rightColumn}`}>
            {sign?.zone && (
              <div className='map-popup-row'>
                <label htmlFor='fff'>{localization.toLanguageStringF('common.zone')}:</label>
                <div className='row-value'>
                  <Link className={styles.link} onClick={openZone}>
                    <span>{sign.zone.Name}</span>
                  </Link>
                </div>
              </div>
            )}

            {sign && (
              <div className='map-popup-row'>
                <label htmlFor='fff'>{localization.toLanguageStringF('common.curbSpace')}:</label>
                <div className='row-value'>
                  <CurbSpacesList spotsStates={sign.spotsStates || []} showIndex={false} />
                </div>
              </div>
            )}

            {sign && (
              <div className='map-popup-row'>
                <label htmlFor='fff'>{localization.toLanguageStringF('common.address')}:</label>
                <div className='row-value'>{sign.Address}</div>
              </div>
            )}

            {sign?.state?.ActiveScreen && (
              <div className={cx('map-popup-row', 'alignItems')}>
                <label htmlFor='fff'>{localization.toLanguageStringF('common.ledColor')}:</label>
                <div className='row-value'>
                  <LedColorText color={sign.state.ActiveScreen.Led} />
                </div>
              </div>
            )}

            {sign?.state?.LastUpdated && (
              <div className='map-popup-row'>
                <label htmlFor='fff'>{localization.toLanguageStringF('common.lastUpdate')}:</label>
                <div className='row-value'>{dateUtils.toLocalUiString(sign.state.LastUpdated)}</div>
              </div>
            )}
          </div>
        </div>

        <div className='map-popup-row more-info'>
          <Link onClick={moreInfo} disabled={selectedSidebar === SidebarName.sign && currentSignData?.id === sign?.Id}>
            {localization.toLanguageStringF('common.moreInfo')}
          </Link>
        </div>
      </div>
    </>
  );

  return (
    <React.StrictMode>
      <SelfOrientedPopup
        data={sign}
        longitude={selectedSignData.position[0]}
        latitude={selectedSignData.position[1]}
        onClose={closePopup}
        closeOnClick={false}
        className='sign-popup'
        maxWidth='395px'
        initPosition={selectedSignData.initPosition}
        onDragEnd={onDragEnd}
      >
        <PopupPlaceholder loading={selectedSignData.loading}>{content}</PopupPlaceholder>
      </SelfOrientedPopup>
    </React.StrictMode>
  );
};

export const SignPopup: FunctionComponent = () => {
  const data = useAppSelector(selectedSigns);
  const zones = data.selected;

  return (
    <>
      {zones.map((x) => (
        <PopupItem key={x.id} selectedSignData={x} />
      ))}
    </>
  );
};
