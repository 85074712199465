import { FC, useEffect } from 'react';
import { Spinner } from '../../../common';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';

import styles from './SidebarSectionLazyLoader.module.scss';

interface Props {
  children: React.ReactNode;
  loadData: () => void;
  hasData: boolean;
  shouldLoadData: boolean;
}

export const SidebarSectionLazyLoader: FC<Props> = ({ children, loadData, hasData, shouldLoadData }) => {
  const localization = useExtendedLocalization();

  useEffect(() => {
    if (shouldLoadData) {
      loadData();
    }
  }, [shouldLoadData, loadData]);

  if (shouldLoadData) {
    return (
      <div className={styles.spinner}>
        <Spinner backdrop={false} />
      </div>
    );
  }

  if (hasData) {
    return <>{children}</>;
  } else {
    return <div className={styles.noData}>{localization.toLanguageStringF(`common.noDataToDisplay`)}</div>;
  }
};
