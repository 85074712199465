import { FunctionComponent } from 'react';
import { useExtendedLocalization } from '../../../hooks/use-extended-localization-service';

import { IRevenueItem, Period } from '../../../model';

import styles from './RevenueTable.module.scss';

type Props = {
  revenue: IRevenueItem[];
};

export const RevenueTable: FunctionComponent<Props> = ({ revenue }) => {
  const localization = useExtendedLocalization();

  const items =
    [...revenue]
      .sort((a, b) => Object.keys(Period).indexOf(a.Period) - Object.keys(Period).indexOf(b.Period))
      .map((x) => ({
        revenue: x,
        name:
          (x.Period === Period.Day && localization.toLanguageStringF('common.period.day')) ||
          (x.Period === Period.Week && localization.toLanguageStringF('common.period.week')) ||
          (x.Period === Period.Month && localization.toLanguageStringF('common.period.month')) ||
          (x.Period === Period.Quarter && localization.toLanguageStringF('common.period.quarter')) ||
          '',
      })) || [];

  return (
    <table className={`table table-borderless table-striped table-sm ${styles.revenueTable}`}>
      <thead>
        <tr>
          <th scope='col'></th>
          <th scope='col'>{localization.toLanguageStringF('revenue.current')}</th>
          <th scope='col'>{localization.toLanguageStringF('revenue.previous')}</th>
          <th scope='col'>{localization.toLanguageStringF('revenue.change')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, idx) => (
          <tr key={idx}>
            <td>{item.name}:</td>
            <td>{item.revenue?.Current}</td>
            <td>{item.revenue?.Previous}</td>
            <td
              style={{
                color: (item.revenue?.Current || 0) > (item.revenue?.Previous || 0) ? '#107C10' : '#D13438',
              }}
            >
              {item.revenue?.Change?.toFixed(2) ?? '0'}%
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
