import {
  IBlockface,
  ICamera,
  ICameraState,
  IMeterName,
  IOccupancyReportItem,
  IParkingEvent,
  ISensorTelemetry,
  ISignState,
  ISpot,
  ISpotOccupancyReport,
  ISpotRegulation,
  ISpotState,
  ISpotStatistics,
  IStudyAreaName,
  IZone,
} from '../../api';

export interface ISelectedCurbSpace extends ISpot {
  signState: ISignState | null;
  camera: ICamera | null;
  zone: IZone | null;
  regulation?: ISpotRegulation | null;
  metersNames: IMeterName[];
  state: ISpotState | null;
  cameraState: ICameraState | null;
  events?: IParkingEvent[];
  cameraEvents: IParkingEvent[];
  sensorTelemetry: ISensorTelemetry | null;
  statistics: ISpotStatistics | null;
  blockface: IBlockface | null;
  occupancyReport?: Array<IOccupancyReportItem>;
  studyAreas: IStudyAreaName[];
  occupancyCustomPeriodReport?: ISpotOccupancyReport | null;
}

export enum SensorFilter {
  All = 'All',
  WithSensors = 'WithSensors',
  WithoutSensors = 'WithoutSensors',
}
