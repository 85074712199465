import { FunctionComponent, useCallback, useMemo } from 'react';
import { Layer, Source, MapLayerMouseEvent } from 'react-map-gl';
import { AnyLayout, AnyPaint, SymbolLayout, SymbolPaint } from 'mapbox-gl';

import { MapImage } from '../../controls/MapImage';
import { useMapLayerHover, useMapLayerPopup, useMapToolTip } from '../../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectedZonesActions, selectZonesGeo, selectZonesLayer, selectZonesGeoLimits } from '../../../../features';
import { ZonePopup } from './ZonePopup';
import { useExtendedLocalization } from '../../../../hooks/use-extended-localization-service';
import { NavigationSource, PopupType, amplitudeService } from '../../../../services';

const COLOR = '#0078D4';
const OPACITY = 0.5;

const zonesLines: AnyPaint = {
  'line-color': COLOR,
  'line-width': {
    base: 6,
    stops: [
      [14, 8],
      [16, 10],
      [17, 12],
    ],
  },
};
const zonesPolygons: AnyPaint = {
  'fill-color': COLOR,
  'fill-opacity': OPACITY,
};
const zonesCircles: AnyPaint = {
  'circle-radius': {
    base: 3,
    stops: [
      [16, 4],
      [17, 8],
      [19, 20],
      [20, 40],
      [21, 60],
    ],
  },
  'circle-color': COLOR,
  'circle-opacity': OPACITY,
};
const zoneCodes: SymbolPaint = {
  'text-color': '#fff',
};
const parkingLimitPaint: SymbolPaint = {
  'text-color': '#fff',
  'text-translate': ['interpolate', ['linear'], ['zoom'], 15, ['literal', [9, -9]], 16, ['literal', [13, -13]], 17, ['literal', [16, -16]]],
  'icon-translate': ['interpolate', ['linear'], ['zoom'], 15, ['literal', [9, -9]], 16, ['literal', [13, -13]], 17, ['literal', [16, -16]]],
};

export const ZonesLayer: FunctionComponent = () => {
  const zoneLinesLayer = 'zones-lines';
  const zonePointsLayer = 'zone-points';
  const zonePolygonsLayer = 'zone-polygons';
  const zoneCodesLayer = 'zone-codes';
  const layers = useMemo(() => [zoneLinesLayer, zonePointsLayer, zonePolygonsLayer, zoneCodesLayer], []);
  const localization = useExtendedLocalization();
  const zonesLayer = useAppSelector(selectZonesLayer);

  useMapToolTip(
    (event: MapLayerMouseEvent) => {
      if (event.features && event.features.length > 0) {
        const code = event.features[0].properties?.code;

        return localization.toLanguageStringF('zone.toolTip', [code]);
      }

      return localization.toLanguageStringF('zone.toolTip', ['']);
    },
    () => zonesLayer.enabled,
    ...layers,
  );

  const dispatch = useAppDispatch();
  const geojson = useAppSelector(selectZonesGeo);
  const limitsGeojson = useAppSelector(selectZonesGeoLimits);

  const layout: AnyLayout = {
    visibility: zonesLayer.enabled ? 'visible' : 'none',
  };
  const codesLayout: SymbolLayout = {
    visibility: zonesLayer.enabled && zonesLayer.displayZoneId ? 'visible' : 'none',
    'text-field': ['get', 'code'],
    'symbol-placement': zonesLayer.timeLimitEnabled ? 'line' : 'line-center',
    'text-allow-overlap': true,
    'text-size': {
      base: 14,
      stops: [
        [15, 10],
        [16, 12],
        [17, 14],
      ],
    },
  };

  const parkingLimitLayout: SymbolLayout = {
    visibility: zonesLayer.enabled && zonesLayer.timeLimitEnabled ? 'visible' : 'none',
    'text-field': ['get', 'limitStr'],
    'text-allow-overlap': true,
    'text-size': {
      base: 14,
      stops: [
        [15, 8],
        [16, 10],
        [17, 14],
      ],
    },
    'icon-image': 'parking-limit-icon',
    'icon-allow-overlap': true,
    'icon-size': {
      base: 0.8,
      stops: [
        [15, 0.6],
        [16, 0.7],
        [17, 0.8],
      ],
    },
  };

  const handleLayerClick = useCallback(
    (evt: MapLayerMouseEvent) => {
      // dispatch(selectedZoneActions.closePopup());
      const feature = evt.features ? evt.features[0] : null;
      if (!feature || !feature.properties) {
        return;
      }

      const zoneId = feature.properties.id;
      const position = [evt.lngLat.lng, evt.lngLat.lat];
      setTimeout(() => {
        dispatch(
          selectedZonesActions.loadZone({
            id: zoneId,
            position: position,
          }),
        );
        amplitudeService.trackPopupOpen(PopupType.Zones, NavigationSource.Map);
      }, 10);
    },
    [dispatch],
  );

  useMapLayerPopup(handleLayerClick, ...layers);

  useMapLayerHover(layers);

  return (
    <>
      <Source id='zones-source' type='geojson' data={geojson} generateId={true}>
        <Layer
          {...{
            id: zonePolygonsLayer,
            type: 'fill',
            layout,
            paint: zonesPolygons,
            filter: ['==', '$type', 'Polygon'],
          }}
        />
        <Layer
          {...{
            id: zoneLinesLayer,
            type: 'line',
            layout,
            paint: zonesLines,
            filter: ['==', '$type', 'LineString'],
          }}
        />
        <Layer
          {...{
            id: zonePointsLayer,
            type: 'circle',
            layout,
            paint: zonesCircles,
            filter: ['==', '$type', 'Point'],
          }}
        />

        <Layer
          {...{
            id: zoneCodesLayer,
            type: 'symbol',
            layout: codesLayout,
            paint: zoneCodes,
            before: 'building-extrusion',
            filter: ['all', ['has', 'code'], ['has', 'center']],
          }}
        />
      </Source>

      <Source id='zones-limits-source' type='geojson' data={limitsGeojson} generateId={true}>
        <Layer
          {...{
            id: 'zones-limit',
            type: 'symbol',
            layout: parkingLimitLayout,
            paint: parkingLimitPaint,
            before: 'building-extrusion',
          }}
        />
        <MapImage name='parking-limit-icon' url='/icons/parking-limit-icon.png' />
      </Source>

      <ZonePopup />
    </>
  );
};
