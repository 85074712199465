import { Action } from '@reduxjs/toolkit';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { combineEpics, StateObservable } from 'redux-observable';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { createInactiveCameraState, ISelectedStudyArea } from '../../../../model';
import { blockfaces, cameras, geoProcessor, meters, reports, revenueData, sensors, spots, studyAreas, zones } from '../../../../services';
import { RootState } from '../../../../store';
import { geoUtils } from '../../../../utils';
import { citiesActions } from '../../../common';
import { mapStateActions } from '../../map-state';
import { selectedStudyAreasActions } from './selected-study-areas-slice';
import { studyAreasGeoActions } from './study-areas-geo-slice';
import { studyAreasLayerActions } from './study-areas-layer-slice';

const loadStudyArea = (id: number, state: RootState): Observable<ISelectedStudyArea> => {
  const existing = state.selectedStudyAreas.selected.find((x) => x.id === id);
  if (existing && existing.entity) {
    return of(existing.entity);
  }

  return zip(
    from(studyAreas.get(id)),
    from(spots.getStudyAreaSpotsStates(id)),
    from(meters.getStudyAreaMeterNames(id)),
    from(sensors.getStudyAreaSensorSpots(id)),
    from(zones.getStudyAreaZoneNames(id)),
    from(blockfaces.getStudyAreaBlockfaceNames(id)),
  ).pipe(
    mergeMap(([studyArea, spotsStates, metersNames, sensors, zones, blockfaces]) =>
      zip(
        of({ studyArea, spotsStates, metersNames, sensors, zones, blockfaces }),
        spotsStates.length ? from(cameras.getCameraBySpotsIds(spotsStates.map((x) => x.SpotId))) : of([]),
      ),
    ),
    mergeMap(([studyArea, camerasIds]) =>
      (camerasIds.length > 0
        ? zip(
            ...camerasIds.map((x) =>
              from(cameras.getState(x.CameraId)).pipe(map((s) => (s === null ? createInactiveCameraState(x.CameraId) : s))),
            ),
          )
        : of([])
      ).pipe(
        map((x) => ({
          studyArea,
          camerasStates: x,
        })),
      ),
    ),
    map((res) => ({
      ...res.studyArea.studyArea,
      spotsStates: res.studyArea.spotsStates,
      metersNames: res.studyArea.metersNames,
      sensors: res.studyArea.sensors,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      camerasStates: res.camerasStates.filter((x) => x !== null).map((x) => x!),
      zones: res.studyArea.zones,
      blockfaces: res.studyArea.blockfaces,
    })),
  );
};

const findStudyFeature = (id: number, state: RootState): Feature<Geometry, GeoJsonProperties> | null => {
  const result = state.studyAreasGeo.data.features.find((x) => x.properties?.id === id);
  return result || null;
};

const studyAreaSelectedEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> =>
  actions$.pipe(
    filter(selectedStudyAreasActions.loadStudyArea.match),
    concatMap((action) =>
      loadStudyArea(action.payload.id, state$.value).pipe(
        mergeMap((x) => {
          if (action.payload.position) {
            const center = action.payload.position;
            const initPosition = action.payload.initPosition;
            return of(
              studyAreasLayerActions.setEnabled(true),
              selectedStudyAreasActions.loadStudyAreaSuccess({
                studyArea: x,
                position: center,
                initPosition: initPosition ? initPosition : center,
              }),
            );
          } else {
            const feature: Feature<Geometry, GeoJsonProperties> = findStudyFeature(action.payload.id, state$.value) || {
              type: 'Feature',
              geometry: { type: 'Polygon', coordinates: [x.Positions] },
              properties: {},
            };

            const center = geoUtils.findCenter(feature).geometry.coordinates;
            return of(
              studyAreasLayerActions.setEnabled(true),
              mapStateActions.setMapCenter(center),
              selectedStudyAreasActions.loadStudyAreaSuccess({ studyArea: x, position: center }),
            );
          }
        }),
        catchError((err) => of(selectedStudyAreasActions.loadStudyAreaFailed(err.message))),
      ),
    ),
  );

const closePopupsEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(mapStateActions.closePopups.match),
    map((_) => selectedStudyAreasActions.closePopups()),
  );

const citySelectedEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(citiesActions.selectCity.match),
    mergeMap(() => of(studyAreasLayerActions.fetchStudyAreas(), selectedStudyAreasActions.collapsePopups())),
  );

const fetchStudyAreasNames = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(studyAreasLayerActions.fetchStudyAreas.match),
    concatMap(() =>
      from(studyAreas.getNames()).pipe(
        map((x) => studyAreasLayerActions.fetchStudyAreasSuccess(x)),
        catchError((err) => of(studyAreasLayerActions.fetchStudyAreasFailed(err.message))),
      ),
    ),
  );

const fetchGeoStudyAreasEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(studyAreasGeoActions.fetch.match),
    switchMap((action) =>
      geoProcessor.loadStudyAreas(action.payload, action.payload.zoom).pipe(
        map((x) => studyAreasGeoActions.fetchSuccess(x)),
        catchError((err) => of(studyAreasGeoActions.fetchFailed(err.message))),
      ),
    ),
  );

const fetchGeoHeatmapStudyAreasEpic = (actions$: Observable<Action>) =>
  actions$.pipe(
    filter(studyAreasGeoActions.fetchHeatmap.match),
    switchMap((action) =>
      geoProcessor.loadHeatmapStudyAreas(action.payload, action.payload.zoom).pipe(
        map((x) => studyAreasGeoActions.fetchHeatmapSuccess(x)),
        catchError((err) => of(studyAreasGeoActions.fetchHeatmapFailed(err.message))),
      ),
    ),
  );

const fetchViolationReportEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreasActions.fetchViolationReport.match),
    concatMap((action) => {
      return from(reports.getStudyAreaEnforcementReport(action.payload.studyAreaId, action.payload.filter)).pipe(
        map((x) => selectedStudyAreasActions.fetchViolationReportSuccess({ studyAreaId: action.payload.studyAreaId, report: x })),
        catchError((err) => of(selectedStudyAreasActions.fetchViolationReportFailed(err.message))),
      );
    }),
  );
};

const fetchRevenueReportEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreasActions.fetchRevenueReport.match),
    concatMap((action) => {
      return from(reports.getStudyAreaRevenueReport(action.payload.studyAreaId, action.payload.filter)).pipe(
        map((x) => selectedStudyAreasActions.fetchRevenueReportSuccess({ studyAreaId: action.payload.studyAreaId, report: x })),
        catchError((err) => of(selectedStudyAreasActions.fetchRevenueReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyTrafficReportEpic = (actions$: Observable<Action>, state$: StateObservable<RootState>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreasActions.fetchOccupancyTrafficReport.match),
    concatMap((action) =>
      from(reports.getStudyAreaOccupancyTrafficReport(action.payload.studyAreaId, action.payload.filter)).pipe(
        map((x) => selectedStudyAreasActions.fetchOccupancyTrafficReportSuccess({ studyAreaId: action.payload.studyAreaId, report: x })),
        catchError((err) => of(selectedStudyAreasActions.fetchOccupancyTrafficReportFailed(err.message))),
      ),
    ),
  );
};

const fetchRevenueEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreasActions.fetchRevenue.match),
    concatMap((action) => {
      return from(revenueData.getRevenueByStudyArea(action.payload)).pipe(
        map((x) => selectedStudyAreasActions.fetchRevenueSuccess({ studyAreaId: action.payload, revenue: x })),
        catchError((err) => of(selectedStudyAreasActions.fetchRevenueFailed(err.message))),
      );
    }),
  );
};

const fetchKpiReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreasActions.fetchKpiReport.match),
    concatMap((action) => {
      return from(reports.getStudyAreaKpiReport(action.payload)).pipe(
        map((x) => selectedStudyAreasActions.fetchKpiReportSuccess({ studyAreaId: action.payload, report: x })),
        catchError((err) => of(selectedStudyAreasActions.fetchKpiReportFailed(err.message))),
      );
    }),
  );
};

const fetchTrafficReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreasActions.fetchTrafficReport.match),
    concatMap((action) => {
      return from(reports.getStudyAreaTrafficReport(action.payload)).pipe(
        map((x) => selectedStudyAreasActions.fetchTrafficReportSuccess({ studyAreaId: action.payload, report: x })),
        catchError((err) => of(selectedStudyAreasActions.fetchTrafficReportFailed(err.message))),
      );
    }),
  );
};

const fetchOccupancyReportEpic = (actions$: Observable<Action>): Observable<Action> => {
  return actions$.pipe(
    filter(selectedStudyAreasActions.fetchOccupancyReport.match),
    concatMap((action) => {
      return from(reports.getStudyAreaOccupancyReport(action.payload)).pipe(
        map((x) => selectedStudyAreasActions.fetchOccupancyReportSuccess({ studyAreaId: action.payload, report: x })),
        catchError((err) => of(selectedStudyAreasActions.fetchOccupancyReportFailed(err.message))),
      );
    }),
  );
};

export const studyAreasEpic = combineEpics(
  citySelectedEpic,
  fetchStudyAreasNames,
  fetchGeoStudyAreasEpic,
  fetchGeoHeatmapStudyAreasEpic,
  studyAreaSelectedEpic,
  closePopupsEpic,
  fetchViolationReportEpic,
  fetchRevenueReportEpic,
  fetchOccupancyTrafficReportEpic,
  fetchRevenueEpic,
  fetchKpiReportEpic,
  fetchTrafficReportEpic,
  fetchOccupancyReportEpic,
);
